import styled from 'styled-components'

import React from 'react'

const CustomLoader = () => {
    return (
        <LoaderContainer>
            <StyledLoader>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </StyledLoader>
        </LoaderContainer>
    )
}

export default CustomLoader

const LoaderContainer = styled.div`
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
`

const StyledLoader = styled.div`
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 32px;
    height: 32px;
    margin: 20px;
    border: 8px solid #000;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #000 transparent transparent transparent;
    :nth-child(1){
        animation-delay: -0.45s;
    }
    :nth-child(2) {
    animation-delay: -0.3s;
    }

    :nth-child(3) {
        animation-delay: -0.15s;
    }
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
`

