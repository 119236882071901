import styled from 'styled-components'

const CustomButtonV2 = styled.button`
    border-radius: 0px;
    background-color: ${props => (props.secondary ? 'gray' : '#163a5f')};
    color: ${props => (props.secondary ? '#ffff' : '#FFF')};
    //padding: 10px 15px;
    outline: none;
    border: none;
    font-size: 1rem;
    cursor: pointer;
    height: 3.25rem;
    -webkit-box-shadow: 2px 2px 5px 0px rgba(0,0,0,0.26);
    -moz-box-shadow: 2px 2px 5px 0px rgba(0,0,0,0.26);
    box-shadow: 2px 2px 5px 0px rgba(0,0,0,0.26);
    border-radius: 10px;
    :disabled{
        background: gray;
        :hover{
            cursor: initial;
            background: gray;
        }
    }

    transition: background .2s ease-in-out;
    :hover{
        background: #205388;
    }
`

export default CustomButtonV2