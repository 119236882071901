import React from 'react'
import PrimaryButton from '../buttons/CustomButtonV2'
import styled from 'styled-components'
import { useLocation, useParams } from 'react-router-dom'
import { useEffect, useState, useContext } from 'react'
import UserContext from '../../context/userContext'
import obeliskApiService from '../../services/obeliskApiService'

import QRCode from 'react-qr-code'
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import mixpanel from 'mixpanel-browser'
import { Helmet } from 'react-helmet'
import errorNoDocImg from '../../assets/errorNoDocImg.svg'
import AppLogo from '../../assets/logohoriznew.png'
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';

const DetailsViewPage = (props) => {

    const params = useParams()

    const userContext = useContext(UserContext);
    const loggedIn = userContext.userData.userId !== undefined;

    const location = useLocation()
    const [documentData, setDocumentData] = useState(location?.state?.document ?? null)
    const [issuerData, setIssuerData] = useState(null)
    // const [blockchainVerificationInProgress, setBlockchainVerificationInProgress] = useState(true);
    // const [documentVerifiedOnBlockchain, setDocumentVerifiedOnBlockchain] = useState(null)
    const [qrCode, setQrCode] = useState(null)
    const [showGenerateButton, setShowGenerateButton] = useState(true)
    const [shareLinkCopied, setSharedLinkCopied] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        const getIssuerDetails = async (issuerId) => {
            console.log("issuer id", issuerId);
            const issuerData = await obeliskApiService.getUserByUserId(issuerId);
            console.log("issuerData", issuerData);
            if (issuerData.data) {
                setIssuerData(issuerData.data);
            }
        }

        const getDocumentDetails = async (documentId) => {
            console.log("docId", documentId)
            try {
                const documentDataResponse = await obeliskApiService.getDocumentDetailsByDocumentId(documentId);
                console.log("doc data", documentDataResponse);
                if (documentDataResponse.data) {
                    setDocumentData(documentDataResponse.data);
                    mixpanel.track("Document viewed");
                    await getIssuerDetails(documentDataResponse.data.issuerId);
                } else {
                    setErrorMessage("Something went wrong while displaying the document.")
                }
            } catch (error) {
                console.error("I caught it", error);
                setErrorMessage(error.response.data.error);
            }
        }

        console.log("logged in", loggedIn);
        console.log(params.txHash);
        console.log("Docid from params", params.documentId)
        getDocumentDetails(params.documentId);
        generateQrCode(params.documentId)
    }, [])

    const generateQrCode = (documentId) => {
        const viewDocumentPath = `viewdocument/${documentId}`
        const baseFrontendPathProd = `https://project-obelisk-production-frontend.netlify.app/${viewDocumentPath}`
        const baseFrontendPathLocal = `http://localhost:3000/${viewDocumentPath}`
        let qrCodeUrl = process.env.REACT_APP_OBELISK_API_URL ?
            baseFrontendPathProd :
            baseFrontendPathLocal;
        console.log("QrCodeUrl: ", qrCodeUrl)
        setQrCode(qrCodeUrl)
    }

    const downloadFile = async () => {
        const baseUrl = process.env.REACT_APP_OBELISK_API_URL ||
            "http://localhost:8000/api/v1"

        const downloadUrl = `${baseUrl}/downloadFile?fileName=${documentData.name.replace(/\s/g, '')}.pdf`
        //const response = await obeliskApiService.downloadFileFromDb(documentData.name.replace(/\s/g, '') + '.pdf');
        window.location.href = downloadUrl;
    }

    const copyUrlToClipboard = () => {
        navigator.clipboard.writeText(qrCode);
        setSharedLinkCopied(true);
        mixpanel.track("Document url copied")
    }

    // Pull to some util file
    const documentExpired = () => {
        if (documentData?.expiryDate) {
            console.log("date check");
            console.log(documentData.expiryDate, new Date());
            return new Date(documentData.expiryDate) <= new Date();
        }

        return false;
    }

    return (
        <DetailsPageContainer loggedIn={loggedIn}>
            <Helmet>
                <meta property="og:title" content="Check out my digital certificate." />
                <meta property="og:type" content="website" />
                <meta property="og:description" content="Made with DocUnique" />
                <meta property="og:image" content="https://picsum.photos/200/300" />
            </Helmet>
            {
                !errorMessage &&
                <BigDetailsCardContainer>
                    <QrCodeContainer>
                        <div style={{ height: "auto", margin: "0 auto", maxWidth: '512px', width: "100%" }}>
                            {
                                qrCode &&
                                <QRCode
                                    style={{ height: "auto", maxWidth: "512px", width: '22rem' }}
                                    value={qrCode}
                                    viewBox={`0 0 256 256`}
                                />
                            }

                        </div>
                        <br />
                        <PrimaryButton onClick={() => downloadFile()}><FileDownloadIcon /> Download document</PrimaryButton>
                        <br />
                        {
                            shareLinkCopied ? (
                                <p style={{ textAlign: 'center', paddingTop: '10px' }}>Url copied to clipboard!</p>
                            ) :
                                (
                                    <PrimaryButton onClick={() => copyUrlToClipboard()}><ContentCopyIcon /> Copy share url</PrimaryButton>
                                )
                        }
                    </QrCodeContainer>
                    <DocumentDetailsContainer>
                        <h3 style={{ fontWeight: 'bold' }}>Document details</h3>
                        <br />
                        
                        <h5><b>Name:</b> {documentData?.name}</h5>
                        <h5><b>Description:</b> {documentData?.description ?? "None"}</h5>
                        <h5><b>Date issued:</b> {documentData?.dateOfIssue ?? "None"}</h5>
                        <div style={{ display: 'flex', gap: '5px' }}>
                            <h5 style={{fontWeight: 'bold'}}>Expiry date: </h5>
                            <h5 style={{ color: documentExpired() ? 'red' : 'green' }}> {documentData?.expiryDate ?? "None"} </h5>
                        </div>
                        {
                            userContext?.userData?.isIssuer &&
                            <h5><b>Owner:</b> {documentData?.ownerName}</h5>
                        }
                        <h5 className="bc-id"><b>Blockchain Id:</b> <a href={'https://mumbai.polygonscan.com/tx/' + documentData?.blockchainHash} target='_blank'>{documentData?.blockchainHash}</a></h5>
                        <h5><b>Authorized by:</b> {documentData?.authorizedPerson ?? "None"}</h5>
                        <br />
                        <br />
                        <h3 style={{ fontWeight: 'bold' }}>Issuer details</h3>
                        <br />
                        <h5><b>Name:</b> {issuerData?.issuerName ?? "None"}</h5>
                        <h5><b>Contact:</b> {issuerData?.issuerContactEmail ?? "None"}</h5>
                        <h5><b>Website:</b> <a href={issuerData?.issuerWebsite ?? "None"}>{issuerData?.issuerWebsite ?? "None"}</a></h5>
                        {
                            documentData?.blockchainHash !== '' && documentData?.documentFileBase64Hash !== '' &&
                            <h5><b>Verified by:</b><img style={{height: '30px', background:'#163a5f', borderRadius: '6px', marginLeft:'15px'}} src={AppLogo}></img><VerifiedUserIcon style={{color: 'green'}}/></h5>
                        }
                    </DocumentDetailsContainer>
                </BigDetailsCardContainer>
            }
            {
                errorMessage &&
                <ErrorWithImage>
                    <img src={errorNoDocImg}></img>
                    <p>Error: {errorMessage}</p>
                </ErrorWithImage>
            }
        </DetailsPageContainer>
    )
}

export default DetailsViewPage

const VerificationBanner = styled.div`
    float: left;
    display: flex;
    align-items: center;
    background: #163a5f;
    border: 3px solid #02A400;
    border-radius: 13px;
    >img{
        height: 30px;
        margin-left: 20px;
       
    }

    >p{
        margin: 0;
        color: white;
    }
    
`

const ErrorWithImage = styled.div`
    height: 70vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    >img{
        height: 10rem;
    }  

    >p{
        margin-top: 20px;
        font-size: 1.25rem;
        text-align: center;
    }
`


const DetailsPageContainer = styled.div`
    background: white;
    padding-top: 80px;
    padding-left: 50px;
    padding-right: 50px;
    height: 100vh;
     @media only screen and (min-width: 1100px) {
        margin-left: ${props => props.loggedIn ? '20%' : '0'} ;
    }

    /* padding: 20px 50px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;   
    border-radius: 10px; */
    /* @media only screen and (min-width: 1100px) {
        margin-left: ${props => props.loggedIn ? '20%' : '0'} ;
    }

    @media only screen and (max-width: 1100px) {
        margin-top: 60px;
        flex-direction: column-reverse;
        gap: 20px;
    }

    display: flex;
    flex-direction: row;
    
    align-items: center;
    justify-content: center;
    gap: 300px;
    flex-wrap: wrap; */
`

const BigDetailsCardContainer = styled.div`
    padding-top: 3rem;
    padding-bottom: 3rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    //background: linear-gradient(270deg, rgba(255,255,255,1) 0%, rgba(242,242,242,1) 65%, rgba(255,255,255,1) 100%);
    border-radius: 20px;
    box-shadow: 0px 2px 38px rgba(0, 0, 0, 0.2);

    a{
        text-decoration: none;
    }
    
    @media only screen and (max-width: 1100px) {
        /* flex-direction: column-reverse;
        padding: 20px; */
    }
`

const QrCodeContainer = styled.div`
    display: flex;
    flex-direction: column;
    img{
        height: 330px;
        width: 330px;
    }
/* padding: 20px 50px;
box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;   
border-radius: 10px; */
 /* @media only screen and (max-width: 1100px) {
        margin-top: 20px;
    }

    margin-top: 100px;
    display: flex;
    flex-direction: column;
    img{
        height: 330px;
        width: 330px;
    } */
`

const DocumentDetailsContainer = styled.div`
    .bc-id{
        width: 20rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    h3{
        font-size: 2rem;
    }
    h5{
        font-size: 1.3rem;
    }    

    @media only screen and (max-width: 580px) {
        margin-top: 3rem;
    }
    /* margin-top: 100px;
    @media only screen and (max-width: 1100px) {
        margin-bottom: 100px;
    }
    h3, h5{
        font-family: "Montserrat";
    }

    h3{
        font-weight: bold;
    } */
`