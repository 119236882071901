import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import DocumentMetadataInputField from './elements/DocumentMetadataInputField'
import PrimaryButton from '../components/buttons/CustomButtonV2'
import obeliskApiService from '../services/obeliskApiService'
import CustomButtonV2 from '../components/buttons/CustomButtonV2'
import CustomLoader from './elements/CustomLoader'
import validator from 'validator'

const DocumentMetadata = (props) => {
    const { currentDocMetadata } = props
    const [minExpiryDate, setMinExpiryDate] = useState(null)
    const [tempRegLoading, setTempRegLoading] = useState(false);
    const [formData, setFormData] = useState({
        name: null,
        description: null,
        dateOfIssue: new Date(),
        expiryDate: null,
        authorizedPerson: null,
        stampId: null,
        ownerEmail: null,
        ownerName: null,
    })

    const [errorMessage, setErrorMessage] = useState(null);
    
    const onOwnerEmailChange = (value) => {
        console.log("Owner email changed");
        console.log(value);
        setFormData({ ...formData, ownerEmail: value })
    }

    const onNameChange = (value) => {
        console.log("Name changed");
        console.log(value);
        setFormData({ ...formData, name: value })
    }

    const onDescriptionChange = (value) => {
        console.log("Description changed");
        console.log(value);

        setFormData({ ...formData, description: value })
    }

    const onDateChange = (value) => {
        console.log("Date changed" + value);
        console.log(value);

        setFormData({ ...formData, dateOfIssue: value })
        setMinExpiryDate(value)
        console.log(value)
    }

    const onExpiryDateChange = (value) => {
        if (value == '') {
            value = null;
        }
        console.log("Expiry date changed" + value);

        console.log(value);

        setFormData({ ...formData, expiryDate: value })
    }

    const onAuthorizedPersonChange = (value) => {
        console.log("Authorized person changed");
        console.log(value);

        setFormData({ ...formData, authorizedPerson: value })
    }

    const onOwnerNameChange = (value) => {
        console.log("Owner name changed: ", value);
        setFormData({...formData, ownerName: value})
    }

    const isEmailValid = (email) => {
        return validator.isEmail(email);
    }

    const handleProceedClick = async () => {
        console.log(props);
        console.log("Data filled");
        const user = await obeliskApiService.getUserByEmail(formData.ownerEmail);
        console.log(user);

        if (!formData.ownerEmail) {
            setErrorMessage("Please specify the owner email.");
            return;
        }

        if(!formData.ownerName){
            setErrorMessage("Please specify the owner name.");
            return;
        }

        if (!formData.name){
            setErrorMessage("Please enter the document name.");
            return;
        }

        if (formData.expiryDate != '' && formData.expiryDate < formData.dateOfIssue) {
            setErrorMessage("Expiry date must be set past the date of issue.")
            return;
        }

        if(!isEmailValid(formData.ownerEmail)){
            setErrorMessage("Please enter a valid owner email.");
            return;
        }

        if (!user.data) {
            setErrorMessage("That user is not registered.")
            let forceRegister = false;
            const regDialog = document.getElementById("regDialog");
            const yesBtn = document.getElementById("regDialogYesBtn");
            yesBtn.addEventListener('click', (e) => {
                e.preventDefault();
                forceRegister = true
                regDialog.close();
            });
            regDialog.showModal();
            regDialog.addEventListener('close', async (e) => {
                if (forceRegister) {
                    console.log("Registering user");
                    const userToRegister =
                    {
                        email: formData.ownerEmail,
                    }
                    const registeredTemp = await obeliskApiService.registerTemporary(userToRegister);
                    if (registeredTemp.data.successful) {
                        props.setDocMetadata(
                            {
                                ...currentDocMetadata,
                                name: formData.name,
                                description: formData.description,
                                dateOfIssue: formData.dateOfIssue,
                                expiryDate: formData.expiryDate,
                                authorizedPerson: formData.authorizedPerson,
                                ownerEmail: formData.ownerEmail,
                                ownerName: formData.ownerName,
                            })
                        console.log(formData);
                        props.setPhase("issue")
                    }
                } else {
                    console.log("No registration");
                }
            })
            //return;
            return;
        }

        props.setDocMetadata(
            {
                ...currentDocMetadata,
                name: formData.name,
                description: formData.description,
                dateOfIssue: formData.dateOfIssue,
                expiryDate: formData.expiryDate,
                authorizedPerson: formData.authorizedPerson,
                ownerEmail: formData.ownerEmail,
                ownerName: formData.ownerName,
            })
        console.log(formData);
        props.setPhase("issue")
    }

    return (

        <DocumentMetadataStepContainer>
            <CustomDialog id="regDialog">
                <p>This email is not registered in our database. Do you want to create a temporary account for this email?</p>
                <form method="dialog">
                    {
                        tempRegLoading ? <CustomLoader /> :
                            <>
                                <CustomButtonV2 id="regDialogYesBtn">Yes</CustomButtonV2>
                                <CustomButtonV2 secondary formmethod="dialog">No</CustomButtonV2>
                            </>
                    }

                </form>
            </CustomDialog>
            {/* <h2>Enter document metadata</h2>
            <br /> */}
            {/* <p className="page-header">Document metadata</p> */}
            {/* TODO: We must work on a better component organization */}
            <DocumentMetadataInputField valueChanged={onOwnerEmailChange} fieldName="Owner email:" type="email" />
            <DocumentMetadataInputField valueChanged={onOwnerNameChange} fieldName="Owner name:" type="email" />
            <DocumentMetadataInputField valueChanged={onNameChange} fieldName="Document title:" type="text" />
            <DocumentMetadataInputField valueChanged={onDescriptionChange} fieldName="Description" type="text" />
            <DocumentMetadataInputField valueChanged={onDateChange} fieldName="Date:" type="date" />
            <DocumentMetadataInputField valueChanged={onExpiryDateChange} fieldName="Expiry date:" type="date" min={minExpiryDate} />
            <DocumentMetadataInputField valueChanged={onAuthorizedPersonChange} fieldName="Authorized person:" type="text" />
            {
                errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>
            }
            <br />
            <PrimaryButton style={{ width: '50%' }} onClick={() => handleProceedClick()}>Proceed</PrimaryButton>
        </DocumentMetadataStepContainer>
    )
}

export default DocumentMetadata

const DocumentMetadataStepContainer = styled.div`
    box-shadow: rgba(0, 0, 0, 0.2) 0px 18px 50px -10px;  
    border-radius: 20px;
    padding: 40px; 
    text-align: center;

    @media only screen and (min-width: 1400px) {
        //margin-left: 20%;
        max-width: 1100px;
    }
    @media only screen and (min-width: 1100px) {
        //margin-left: 20%;
        max-width: 750px;
    }

    @media only screen and (max-width: 1100px) {
        //margin-top: 100px;
        max-width: 500px;
    }
   
    width: 90vw;
    //max-width: 500px;
    //margin-bottom: 100px;
    p.page-header{
        //font-size: 1.5rem;
        text-align: center;
        //font-weight: bold;
        color: #003459;
    }
    margin-top: 50px;

`

const CustomDialog = styled.dialog`
    width: 30rem;
    padding: 30px;
    height: 12rem;
    border: none;
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
    border-radius: 15px;
    form{
        display: flex;
        flex-direction: row;
        justify-content: center;
        button{
            width: 80px;
        }
        gap: 20px;
    }
    @media only screen and (min-width: 1100px) {
        margin-left: 39rem;
    }
`

const ErrorMessage = styled.p`
    color: #EE4B2B;
`

