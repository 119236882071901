import React from 'react'
import styled from 'styled-components'
import StepperBorder from '../../assets/stepperBorder3.svg'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const CustomStepTracker = ({ finishedStep }) => {
    return (
        <StepTrackerContainer>
            <Step>
                <p>{finishedStep == 1 || finishedStep == 2 || finishedStep == 3 ? <CheckCircleIcon className='icon' /> : '1.'} Upload</p>
            </Step>
            <img style={{ height: '5rem' }} src={StepperBorder}></img>
            <Step>
                <p>{finishedStep == 2 || finishedStep == 3 ? <CheckCircleIcon className='icon' /> : '2.'} Metadata</p>
            </Step>
            <img style={{ height: '5rem' }} src={StepperBorder}></img>
            <Step>
                <p>{finishedStep == 3 ? <CheckCircleIcon className='icon' /> : '3.'} Issue</p>
            </Step>
        </StepTrackerContainer>
    )
}

export default CustomStepTracker

const StepTrackerContainer = styled.div`
    padding-left: 2rem;
    padding-right: 2rem;
    display: flex;
    flex-direction: row;
    height: 5rem;
    width: 80%;
    justify-content: space-evenly;
    align-items: center;
    align-content: center;
    //border: 1px solid #1C6EA4;
    border-radius: 20px 0px 19px 0px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 18px 50px -10px;   
    background: white;
`

const Step = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    >p{
        margin: 0px;
        .icon{
            color: #1C6EA4;
        font-size: 2rem !important;
        }
    }
    
`