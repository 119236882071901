import {httpJsonHandler, httpFormHandler} from "../helpers/HttpCommon"

class ObeliskApiService {

    // TODO: Refactor API and this service to use a more generic approach with filters
    // and DON'T FORGET PAGING!!!
    getUsersByParty(partyId) {
        return httpJsonHandler.get(`/partyusers?partyId=${partyId}`)
    }

    getUserByUserId(userId) {
        return httpJsonHandler.get(`/user?userId=${userId}`)
    }

    insertDocumentMetadata(documentMetadata) {
        console.log("servicemetadata", documentMetadata);
        return httpJsonHandler.post('/documentMetadata', documentMetadata)
    }

    uploadDocumentToDb(documentFile, docName){
        const form = new FormData()
        form.append('file', documentFile)
        form.append('docName', docName)
        return httpFormHandler.post('/uploadFile', form)
    }

    downloadFileFromDb(fileName){
        return httpJsonHandler.get(`/downloadFile?fileName=${fileName}`)
    }

    getIssuerDocumentsByIssuerId(issuerId, options = null){
        return httpJsonHandler.post(`/issuerdocuments?issuerId=${issuerId}`, options)
    }

    getUserDocumentsByWalletAddress(walletAddress, options = null){
        return httpJsonHandler.post(`/userdocumentsbyaddress?walletAddress=${walletAddress}`, options)
    }

    getDocumentDetailsByDocumentId(documentId){
        return httpJsonHandler.get(`/documentbyid?documentId=${documentId}`)
    }

    getUserByEmail(email){
        return httpJsonHandler.get(`/userbyemail?email=${email}`)
    }

    login(userData){
        return httpJsonHandler.post('/login', userData)
    }

    register(userData){
        return httpJsonHandler.post('/register', userData);
    }

    registerTemporary(userData){
        return httpJsonHandler.post('/registerTemp', userData);
    }

    searchDocumentByName(searchTerm){
        return httpJsonHandler.get(`/searchdocuments?searchTerm=${searchTerm}`)
    }

    changePassword(data){
        return httpJsonHandler.put(`/changepassword`, data);
    }

    resetPassword(data){
        return httpJsonHandler.put(`/resetpassword`, data);
    }

    updateUserSettings(data){
        return httpJsonHandler.put(`/updatesettings`, data);
    }

    sendEmailNotification(notificationObject){
        return httpJsonHandler.post(`/sendemailnotification`, notificationObject);
    }

    sendEmailVerification(dataObject){
        return httpJsonHandler.post(`/sendemailverification`, dataObject);
    }

    sendForgotPasswordLink(dataObject){
        return httpJsonHandler.post(`/sendpasswordreset`, dataObject);
    }

    confirmUserEmail(token){
        return httpJsonHandler.get(`/emailverificationtokencheck?token=${token}`)
    }

    passwordResetTokenCheck(token){
        return httpJsonHandler.get(`/passwordresettokencheck?token=${token}`)
    }
}

export default new ObeliskApiService();
