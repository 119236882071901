import React from 'react'
import styled from 'styled-components'

const ErrorComponent = (props) => {
  return (
    <ErrorContainer>
      <img alt={props.errorMsg} src={props.imgSrc}></img>
      <br />
      {/* <p>Oops. Looks like the page you are looking for does not exist.</p> */}
      <p>{props.errorMsg}</p>
    </ErrorContainer>
  )
}

export default ErrorComponent

const ErrorContainer = styled.div`
height: 100vh;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
>p{
    color: gray;
    font-size: 1.2rem;
    text-align: center;
    padding: 10px;
}

>img{
  height: 10rem;
}
    
`