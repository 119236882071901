import React, { useState } from 'react'
import styled from 'styled-components';
import PrimaryButton from '../components/buttons/CustomButtonV2'

const UploadDocument = (props) => {

    const [fileUploaded, setFileUploaded] = useState(false)
    //const [documentFile, setDocumentFile] = useState(null)
    const [fileError, setFileError] = useState('')
    const documentType = ['application/pdf']


    const handleNextStepClick = () => {
        console.log("Document uploaded");
        props.setPhase("metadata")
    }

    const handleDocumentFileChanged = (e) => {
        let selectedFile = e.target.files[0] // TODO: Expand for multiple docs
        if (selectedFile) {
            if (documentType.includes(selectedFile.type)) {
                let reader = new FileReader()
                reader.readAsDataURL(selectedFile)
                reader.onloadend = (e) => {
                    props.setDocumentFile(selectedFile)
                    props.setDocumentBase64(e.target.result.replace("data:application/pdf;base64,", ''))
                    setFileError('')
                    setFileUploaded(true)
                }
            } else {
                props.setDocumentFile(null)
                setFileUploaded(false)
                setFileError("Invalid type or format of the document.")
            }
        }
    }

    return (
        <UploadDocumentContainer>
            {/* <DashedBox>
                <p>Please select a file for upload</p>
                <input type="file" onChange={handleDocumentFileChanged}></input>
            </DashedBox> */}

            <input type="file" onChange={handleDocumentFileChanged}></input>

            {fileError && <ErrorLabel><h5>{fileError}</h5></ErrorLabel>}
            {
                fileUploaded && (
                    <div>
                        <br />
                        <br />
                        <PrimaryButton style={{ width: '10rem' }} onClick={() => handleNextStepClick()}>Next step</PrimaryButton>
                    </div>
                )
            }
        </UploadDocumentContainer>
    )
}

export default UploadDocument

const DashedBox = styled.div`
    position: absolute;
    top: 20%;
    height: 20rem;
    width: 50rem;
    border: 4px dashed #205388;
    >input{
        position: absolute;
        margin: 0;
        padding: 0;
        width: 100%;
        height: 100%;
        outline: none;
        opacity: 0;
    }

    >p{
        width: 100%;
        height: 100%;
        text-align: center;
        line-height: 170px;
    }
`

const UploadDocumentContainer = styled.div`
    margin-top: 150px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 18px 50px -10px;   
    padding: 50px;
    border-radius: 20px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;

    input[type=file]::file-selector-button {
        margin-right: 20px;
        border: none;
        background: #163a5f;
        padding: 10px 20px;
        border-radius: 6px;
        color: #fff;
        cursor: pointer;
        transition: background .2s ease-in-out;
    }

    input[type=file]::file-selector-button:hover {
    background: #205388;
    }
`

const ErrorLabel = styled.div`
    padding-top: 30px;
    color: red;
`