import './App.css';
import { Route, Routes, Outlet, Navigate } from 'react-router';
import IssueDocuments from './components/IssueDocuments'
import UserContext from './context/userContext'
import { useEffect, useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';

import ErrorComponent from './components/common/ErrorComponent';
import CustomSideBarV2 from './components/common/CustomSideBarV2';
import MainAppPage from './components/pagesV2/MainAppPage';
import DetailsViewPage from './components/pagesV2/DetailsViewPage';
import GlobalStyle from './globalStyles';
import { ToastContainer } from 'react-toastify';
import SettingsPage from './components/pagesV2/SettingsPage';
import mixpanel from 'mixpanel-browser'
import VerifyEmailPage from './components/pagesV2/VerifyEmailPage';
import ResetPasswordPage from './components/pagesV2/ResetPasswordPage';
import LandingPageV3 from './components/pagesV2/LandingPageV3';
import setAuthHeader from './helpers/HttpCommon';
import errorImg from './assets/errorPageImg.svg';

function App() {
  console.log(process.env.REACT_APP_ADMIN_WALLET_PRIVATE_KEY);
  console.log(process.env.REACT_APP_MIXPANEL_TOKEN);
  if(process.env.REACT_APP_MIXPANEL_TOKEN){
    mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, { debug: true });
    console.log("mixpanel loaded", mixpanel);
  } else {
    console.error("mixpanel failed to load. Missing project token!");
  }

  const [userData, setUserData] = useState({
    token: undefined,
    userId: undefined,
    isIssuer: undefined,
    issuerName: undefined,
    email: undefined,
    walletAddress: undefined,
  });

  //const [loggedIn, setLoggedIn] = useState(false);


  useEffect(() => {
    const checkIfLoggedIn = async () => {
      let token = localStorage.getItem("auth-token");
      console.log("token from storage", token);
      if (token === null || token === '') {
        localStorage.setItem("auth-token", "");
        token = "";
      }

      if (token != '') {
        try {

          let tokenCheckUrl = '';
          if (process.env.REACT_APP_OBELISK_API_URL) {
            tokenCheckUrl = `${process.env.REACT_APP_OBELISK_API_URL}/tokencheck`
          } else {
            tokenCheckUrl = "http://localhost:8000/api/v1/tokencheck"
          }
          const config = {
            method: 'get',
            url: tokenCheckUrl,
            headers: {
              "obelisk-auth-token": token
            }
          }
          //const tokenResponse = await axios.get('http://localhost:8000/api/v1/tokencheck', null, { headers: { "obelisk-auth-token": token } });
          const tokenResponse = await axios(config);
          console.log("token response", tokenResponse);
          if (tokenResponse.data.success) {
            setAuthHeader(token);
            //setLoggedIn(true);

            //const loggedUser = await obeliskApiService.getUserByUserId(tokenResponse.data.userId);
            //console.log("Logged user", loggedUser.data);
            //navigate('/dashboard')
            setUserData(prev => {
              return {
                ...prev,
                token: token,
                email: tokenResponse.data.userEmail,
                userId: tokenResponse.data.userId,
                walletAddress: tokenResponse.data.walletAddress,
                isIssuer: tokenResponse.data.isIssuer,
                issuerName: tokenResponse.data.issuerName,
                userName: tokenResponse.data.userName,
                issuerWebsite: tokenResponse.data.issuerWebsite,
                issuerContactEmail: tokenResponse.data.issuerContactEmail,
                documentBalance: tokenResponse.data.documentBalance,
              }
            })
          } else if (tokenResponse.data.error == "jwt expired") {
            // TODO: Improve UX for expiration
            localStorage.setItem("auth-token", "");
            token = "";
            //navigate('/');
          }

        } catch (error) {
          console.log("Error", error);
        }
      }

      console.log(token);
    }
    checkIfLoggedIn();
  }, [])


  return (
    <div className="App">
      <GlobalStyle />
      <UserContext.Provider value={{ userData, setUserData }}>
        <MainPageContent>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
          {/* Same as */}
          <ToastContainer />
          <Routes>
            <Route path='/viewdocument/:documentId' element={<DetailsViewPage />}></Route>
            <Route path='/verifyuser' element={<VerifyEmailPage />}></Route>
            <Route path='/resetpassword' element={<ResetPasswordPage />}></Route>

            {
              userData.userId == undefined ? (
                <Route path="/" element={<LandingPageV3 />}></Route>

              ) : (
                <>
                  <Route element={<MainAppLayout />}>
                    <Route path={"/"} element={<Navigate to='/dashboard'/>}/>
                    <Route path={"/dashboard"} element={<MainAppPage />} />
                    <Route path={"/dashboard/document/:documentId"} element={<DetailsViewPage />} />
                    <Route path={"/settings"} element={<SettingsPage />} />
                  </Route>
                </>
              )
            }
            {
              userData.isIssuer == true &&
              <Route element={<MainAppLayout />}>
                <Route path={"/issue"} element={<IssueDocuments />} />
              </Route>
              // route for user management
              // route for something something
            }
            {/* <Route path="/whatisobelisk" element={<AboutObelisk />}></Route> */}
            {console.log("logged in", userData.userId != undefined)}
            {/* <Route path="/*" element={<Navigate to='/error'/>}></Route> */}
            <Route path="/error" element={<ErrorComponent imgSrc={errorImg} errorMsg="Oops. Looks like the page you are looking for does not exist or you are not logged in." />}></Route>
            <Route path="/*" element={<ErrorComponent imgSrc={errorImg} errorMsg="Oops. Looks like the page you are looking for does not exist or you are not logged in." />}></Route>
          </Routes>
        </MainPageContent>
      </UserContext.Provider>
    </div>
  );
}

function MainAppLayout() {
  return (
    <div className="app">
      <CustomSideBarV2 />
      <Outlet />
    </div>
  )
}

export default App;

const MainPageContent = styled.div`
  height: 100vh;
//margin-left: 20%;
/* Required for sidebar */

/* margin-left: 200px;
  padding: 1px 16px;
  height: 100vh; */

`