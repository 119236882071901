import React, { useState, useContext } from 'react'
import DocumentMetadata from './DocumentMetadata'
import UploadDocument from './UploadDocument'
import IssueStep from './IssueStep'
import UserContext from '../context/userContext'
import "react-step-progress-bar/styles.css";
import styled from 'styled-components'
import CustomStepTracker from './elements/CustomStepTracker'
import BalanceErrorImg from '../assets/noMoreTokens.svg'
import ErrorComponent from './common/ErrorComponent'

const IssueDocuments = () => {

    const { userData, setUserData } = useContext(UserContext);

    const [documentFile, setDocumentFile] = useState(null)
    const [documentBase64, setDocumentBase64] = useState(null)
    const [documentMetadata, setDocumentMetadata] =
        useState(
            {
                name: null,
                description: null,
                dateOfIssue: null,
                expiryDate: null,
                authorizedPerson: null,
                userId: null,
                stampId: null,
                ownerEmail: null,
                ownerName: null,
            })

    const [phase, setPhase] = useState("upload")

    // TODO: Solve somehow with accomplished flag
    let finishedStep = 0;
    if (phase == "upload") {
        finishedStep = 1;
    } else if (phase == "metadata") {
        finishedStep = 2;
    } else if (phase == "issue") {
        finishedStep = 3;
    }

    return (
        <IssueDocumentsPageContainer style={{ height: '100vh' }}>
            {
                userData.documentBalance > 0 ?
                    <>
                        <CustomStepTracker className='stepper' finishedStep={finishedStep} />
                        <IssueStepsContainer>
                            {
                                phase == "upload" &&
                                (
                                    <UploadDocument
                                        setDocumentFile={setDocumentFile}
                                        setDocumentBase64={setDocumentBase64}
                                        setPhase={setPhase}
                                        currentDocMetadata={documentMetadata}
                                        setDocMetadata={setDocumentMetadata}>
                                    </UploadDocument>
                                )
                            }
                            {
                                phase == "metadata" &&
                                (
                                    <DocumentMetadata
                                        setPhase={setPhase}
                                        currentDocMetadata={documentMetadata}
                                        setDocMetadata={setDocumentMetadata}>
                                    </DocumentMetadata>
                                )
                            }
                            {
                                phase == "issue" &&
                                (
                                    <IssueStep
                                        documentFile={documentFile}
                                        documentBase64={documentBase64}
                                        currentDocMetadata={documentMetadata}
                                        setPhase={setPhase}
                                        setDocMetadata={setDocumentMetadata}
                                    >
                                    </IssueStep>
                                )
                            }
                            <br />
                        </IssueStepsContainer>
                    </> :

                    <ErrorComponent imgSrc={BalanceErrorImg} errorMsg="You need to top up your token balance in order to issue a document."></ErrorComponent>
                    // <InsufficientBalanceError>
                    //     <p>You need to top up your token balance in order to issue a document.</p>
                    // </InsufficientBalanceError>
            }

        </IssueDocumentsPageContainer>
    )
}

export default IssueDocuments

const InsufficientBalanceError = styled.div`
    p{
        color: red;
    }
`

const IssueDocumentsPageContainer = styled.div`

    @media only screen and (min-width: 1100px) {
        margin-left: 20%;
    }

    @media only screen and (max-width: 1100px) {
        padding-top: 120px;
    }
    display: flex;
    flex-direction: column;
    //justify-content: center;
    align-items: center;  
    padding-top: 35px;
`
const IssueStepsContainer = styled.div`
    //margin-top: 60px;
    
`
const StyledStep = styled.div`
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    background-color: #003459;
    border-radius: 8px;
    color: white;
    height: 3.125rem;
    width: 6.25rem;
    
`

const ProgressBarContainer = styled.div`
    margin-left: auto;
    margin-right: auto;
    left: 20%;
    right: 0;
    bottom: 60px;
    width: 700px;

    @media only screen and (min-width: 1100px) {
        position: absolute;
    }

    @media only screen and (max-width: 1100px) {
        margin-left: auto;
        margin-top: 100px;
        width: 30rem;
        margin-right: auto;
    }
`