import React, { useEffect, useState, useRef } from 'react'
import styled from 'styled-components'
import { useSearchParams, useLocation } from 'react-router-dom'
import obeliskApiService from '../../services/obeliskApiService';
import CustomLoader from '../elements/CustomLoader.jsx';
import { useNavigate } from 'react-router';
import CustomButtonV2 from '../buttons/CustomButtonV2';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { utils } from '../../helpers/utils';

const ResetPasswordPage = () => {

    const navigate = useNavigate()

    const [searchParams, setSearchParams] = useSearchParams();
    const [userConfirmed, setUserConfirmed] = useState(false);
    const [passwordUpdated, setPasswordUpdated] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [userId, setUserId] = useState(null)
    const [errorMessage, setErrorMessage] = useState('');
    const [oldPwError, setOldPwError] = useState('');
    const [newPwError, setNewPwError] = useState('');

    const newPwRef = useRef();
    const confirmNewPwRef = useRef();

    useEffect(() => {
        console.log('rerender');
        const token = searchParams.get("token");
        const confirmUser = async (token) => {
            const result = await obeliskApiService.passwordResetTokenCheck(token);
            console.log(result.data.error);
            if (result.data.error) {
                setErrorMessage(result.data.error);
                setIsLoading(false);
                return
            }

            if (result.data.successful) {
                setUserConfirmed(true);
                setUserId(result.data.userId);
                setIsLoading(false);
            } else {
                setUserConfirmed(false);
                setIsLoading(false);
                setErrorMessage(result.data.message);
            }
        }

        setIsLoading(true);
        confirmUser(token);
    }, [])

    const isPasswordValid = (password) => {
        var re = utils.passwordRegex;
        return re.test(password)
    }

    const resetPassword = async () => {
        setIsLoading(true);
        const newPwValue = newPwRef.current.value;
        const newPwConfirmValue = confirmNewPwRef.current.value;
        const newPwValid = isPasswordValid(newPwValue);
        const newPwConfirmValid = isPasswordValid(newPwConfirmValue);
        // const { oldPassword, newPassword, userId } = req.body;<div className=""></div>

        if (!newPwValid) {
            setIsLoading(false);
            setOldPwError('Please enter a valid password.')
            return
        }

        if (!newPwConfirmValid) {
            setIsLoading(false);
            setNewPwError('Please enter a valid password.')
            return
        }

        if (newPwValue != newPwConfirmValue){
            setIsLoading(false);
            setNewPwError('Passwords are not matching.')
            return
        }

        if (!userId) {
            setIsLoading(false);
            setErrorMessage('Something went wrong while fetching the user.')
            return
        }

        try {
            if (newPwValid && newPwConfirmValid && userId && newPwValue == newPwConfirmValue) {
                const result = await obeliskApiService.resetPassword({ newPassword: newPwValue, userId: userId })
                if (result.data.successful) {
                    setIsLoading(false);
                    setPasswordUpdated(true);
                } else {
                    setIsLoading(false);
                    setPasswordUpdated(false);
                    setErrorMessage(result.data.message)
                }
            }

        } catch (error) {
            setIsLoading(false);
            setErrorMessage(error);
        }
    }

    const clearErrors = () => {
        setErrorMessage('');
        setOldPwError('');
        setNewPwError('');
    }

    const onNewPasswordEntered = (e) => {
        clearErrors();
        newPwRef.current.value = e.target.value;
    }

    const onConfirmNewPasswordEntered = (e) => {
        clearErrors();
        confirmNewPwRef.current.value = e.target.value;
    }

    const logout = () => {
        //setUserLoggedIn(false);
        localStorage.setItem("auth-token", "");
        navigate('/')
    };

    return (
        <MainContainer>
            {
                isLoading && <CustomLoader />
            }
            {
                !isLoading && userConfirmed && !passwordUpdated &&
                <>
                    <CustomInputField>
                        <label>Enter your new password</label>
                        <input ref={newPwRef} type="password" autocomplete="refpw-12" onChange={(e) => onNewPasswordEntered(e)}></input>
                    </CustomInputField>
                    {oldPwError && <ErrorLabel>{oldPwError}</ErrorLabel>}
                    <CustomInputField>
                        <label>Confirm your new password</label>
                        <input ref={confirmNewPwRef} type="password" autocomplete="newnew333" onChange={(e) => onConfirmNewPasswordEntered(e)}></input>
                    </CustomInputField>
                    {newPwError && <ErrorLabel>{newPwError}</ErrorLabel>}
                    {
                        !passwordUpdated &&
                        <CustomButtonV2 onClick={() => resetPassword()}>Reset password</CustomButtonV2>
                    }
                </>
            }
            {
                !isLoading && passwordUpdated &&
                <>
                    <p>Your password has been updated.  <CheckCircleIcon style={{ color: 'green', height: '30px', width: '30px' }}></CheckCircleIcon></p>
                    <CustomButtonV2 onClick={() => logout()}>Proceed to login</CustomButtonV2>
                </>
            }
            {
                errorMessage && <ErrorLabel style={{fontSize: '1.2rem', textAlign: 'center', marginTop:'20px'}}>{errorMessage}</ErrorLabel>
            }
        </MainContainer>
    )
}

export default ResetPasswordPage;

const MainContainer = styled.div`
    padding-top: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    p{
        margin-top: 50px;
        font-size: 1.2rem;
    }

    button{
        width: 200px;
    }
`

const CustomInputField = styled.div`
    display: flex;
    flex-direction: column;
    width: 70%;
    margin-bottom: 0.938rem;

    label{
        font-size: 1rem;
        @media only screen and (min-width: 350px) and (max-width: 580px) {
            font-size: 16px;;
        }
    }

    >input{
        -webkit-appearance: none;
        padding-left: 20px;
        height: 3.125rem;
        border: none;
        //box-shadow: 0px 4px 10px 3px rgba(0, 0, 0, 0.25);
        //box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
        border: 1px solid #205388;
        border-radius: 7px;
    }
`

const ErrorLabel = styled.div`
    align-self: center;
    color: red;
    font-size: 0.75rem;
    width: 70%;
`