import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useNavigate } from 'react-router'
import SortIcon from '@mui/icons-material/ArrowDropDown';

const CustomTableV2 = (props) => {

    const {documents, isIssuer} = props;

    const navigate = useNavigate()

    const handleRowClick = (document) =>{
        console.log("Clicked on a row, sending document: ", document);
        console.log(document);
        navigate(`document/${document._id}`)
    }

    const documentExpired = (doc) => {
        if(doc?.expiryDate){
            return new Date(doc.expiryDate) <= new Date();
        }

        return false;
    }

    return (
        <TableContainer>
            <table>
                <thead>
                    <TableRowElementColumns>
                        <th className="col-name" scope="col">Name <SortIcon style={{fontSize: '1.4rem', padding: 0, margin: 0, color: '#ffffff'}}/></th>
                        <th className="col-date" scope="col">Issued on <SortIcon style={{fontSize: '1.4rem', padding: 0, margin: 0, color: '#ffffff'}}/></th>
                        <th className="col-issuer" scope="col">{isIssuer ? "Owner" : "Issuer"} <SortIcon style={{fontSize: '1.4rem', padding: 0, margin: 0, color: '#ffffff'}}/></th>
                        <th className="col-exp" scope="col">Expiry date <SortIcon style={{fontSize: '1.4rem', padding: 0, margin: 0, color: '#ffffff'}}/></th>
                    </TableRowElementColumns>
                </thead>
                <tbody>
                    {
                        documents.map((item, index) => {
                            return (
                                <TableRowElement onClick={() => handleRowClick(documents[index])} key={index}>
                                    <td className="row-name">{item.name}</td>
                                    <td className="row-date">{item.dateOfIssue ?? "None"}</td>
                                    <td className="row-issuer">{isIssuer ? item.ownerName : item.issuerName}</td>
                                    <td className="row-exp" style={{ color: documentExpired(item) ? 'red' : 'green'}}>{item.expiryDate ?? "None"}</td>
                                </TableRowElement>
                            )
                        })
                    }
                </tbody>
            </table>

        </TableContainer>
    )
}

export default CustomTableV2

const TableContainer = styled.div`
border-radius: 5px;
  overflow: hidden;
    table{
        tbody{
            box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
        }
        width: 70vw;
        border-collapse: collapse; //separate
        //border-spacing: 0 1px; // with separate
        @media only screen and (max-width: 500px) {
            width: 85vw;
            .row-issuer, .col-issuer{
                display: none;
            }
            font-size: 0.85rem;
        }
    }
    
`

const TableRowElementColumns = styled.tr`
    
    th{
        background:#163a5f;
        color: #ffffff;
        padding-left: 15px;
    }
`

const TableRowElement = styled.tr`
    :hover{
        cursor: pointer;
        background-color: rgba(128, 128, 128, .3) !important;
    }
    line-height: 50px;
    border-bottom: 0.1px solid black; // only with collapse
    //background-color: #f2f2f2;
    /* :first-child td:first-child { border-top-left-radius: 5px; }
    :first-child td:last-child { border-top-right-radius: 5px; }
    :last-child td:first-child { border-bottom-left-radius: 5px; }
    :last-child td:last-child { border-bottom-right-radius: 5px; } */

    :nth-child(even) {
        background: #ffffff;    }
    :nth-child(odd) {
        background: rgba(25, 66, 107, 0.1);
    }   

    :last-child{
        border-bottom: none;
    }

    //:first-child td { border-top-style: solid; } td:first-child { border-left-style: solid; }

    td{
        color: black;
        padding-left: 15px;
        //border-radius: 5px;
    }
    /* @media screen and (max-width: 800px) {
        tr { 
            display: flex; 
            flex-direction: row;
            flex-wrap: wrap;
            margin: 0.5em 0;
            border: 1px solid rgba(3,3,3,0.2);
        }
        td, th {
            flex: 1 1 150px;
            border: 0.5px solid rgba(3,3,3,0.2);
        }
    } */

`