import axios from "axios"

// export default axios.create({
//     baseURL: "http://localhost:8000/api/v1",
//     headers: {
//         "Content-Type" : "application/json"
//     }
// })


export const httpFormHandler = axios.create({
    baseURL: process.env.REACT_APP_OBELISK_API_URL || "http://localhost:8000/api/v1",
    headers: {
        //"Content-Type" : "multipart/form-data; boundary=XXX"
    }
})

export const httpJsonHandler = axios.create({
    baseURL: process.env.REACT_APP_OBELISK_API_URL || "http://localhost:8000/api/v1",
    headers: {
        "Content-Type" : "application/json"
    }
})

const setAuthHeader = (token) => {
    if (token){
        httpJsonHandler.defaults.headers.common["obelisk-auth-token"] = token;
        httpFormHandler.defaults.headers.common["obelisk-auth-token"] = token;
    } else {
        delete httpJsonHandler.defaults.headers.common["obelisk-auth-token"];
        delete httpFormHandler.defaults.headers.common["obelisk-auth-token"];
    }
};

export default setAuthHeader;


