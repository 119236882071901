import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useSearchParams, useLocation } from 'react-router-dom'
import obeliskApiService from '../../services/obeliskApiService';
import CustomLoader from '../elements/CustomLoader.jsx';
import { useNavigate } from 'react-router';
import CustomButtonV2 from '../buttons/CustomButtonV2';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const VerifyEmailPage = () => {

    const navigate = useNavigate()

    const [searchParams, setSearchParams] = useSearchParams();
    const [userConfirmed, setUserConfirmed] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        const token = searchParams.get("token");
        const confirmUser = async (token) => {
            const result = await obeliskApiService.confirmUserEmail(token);
            console.log(result);
            if (result.data.error) {
                setErrorMessage(result.data.error);
            }
            setUserConfirmed(result.data.successful);
            setIsLoading(false);
        }

        setIsLoading(true);
        confirmUser(token);
    }, [])


    return (
        <MainContainer>
            {
                isLoading && <CustomLoader />
            }
            {
                !isLoading && userConfirmed &&
                <>
                    <p>Thank you for confirming your email!  <CheckCircleIcon style={{ color: 'green', height: '30px', width: '30px' }}></CheckCircleIcon></p>
                    <CustomButtonV2 onClick={() => navigate('/')}>Proceed to login</CustomButtonV2>
                </>
            }
            {
                !isLoading && !userConfirmed &&
                <>
                    <br />
                    <p>Something went wrong while confirming your email.</p>
                </>
            }
            <p>{errorMessage}</p>

        </MainContainer>
    )
}

export default VerifyEmailPage

const MainContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    p{
        margin-top: 50px;
        font-size: 18px;
    }

    button{
        width: 200px;
    }
`