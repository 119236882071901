import React, { useContext, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import UserContext from '../../context/userContext';
import obeliskApiService from '../../services/obeliskApiService';
import styled from 'styled-components'
import HDLogo from '../../assets/logohoriznew.png'
import LandingImg from '../../assets/synfilesimg.svg'
import LandingImgMobile from '../../assets/adddocimg.svg'
import CustomButtonV2 from '../buttons/CustomButtonV2'
import LandingPolygons from '../../assets/svgviewer-output.svg'
import CustomLoader from '../elements/CustomLoader.jsx';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';
import setAuthHeader from '../../helpers/HttpCommon';
import { utils } from '../../helpers/utils';

const LandingPageV3 = () => {
    const { userData, setUserData } = useContext(UserContext);

    const [displayRegister, setDisplayRegister] = useState(false);
    const [userLoggedIn, setUserLoggedIn] = useState(false);
    const [userType, setUserType] = useState('user');
    const [errors, setErrors] = useState({
        error: '',
        emailError: '',
        passwordError: '',
        confirmPasswordError: ''
    });
    const [isLoading, setIsLoading] = useState(false);
    const [unconfirmed, setIsUnconfirmed] = useState(false);
    const [generalMessage, setGeneralMessage] = useState('');

    const emailFieldRef = useRef();
    const passwordFieldRef = useRef();
    const confirmPasswordFieldRef = useRef();

    const navigate = useNavigate();

    useEffect(() => {
        emailFieldRef.current.value = '';
        passwordFieldRef.current.value = '';
        if (displayRegister) {
            confirmPasswordFieldRef.current.value = '';
        }
    }, [])

    const clearErrors = () => {
        setErrors({
            ...errors,
            error: '',
            emailError: '',
            passwordError: '',
            confirmPasswordError: ''
        });

        // if (displayRegister) {
        //     setErrors({...errors, confirmPasswordError: ''});
        // }
    }

    const clearAllValuesAndErrors = () => {
        setErrors({
            ...errors,
            error: '',
            emailError: '',
            passwordError: '',
            confirmPasswordError: ''
        });
        setGeneralMessage('');
        emailFieldRef.current.value = '';
        passwordFieldRef.current.value = '';
        if (displayRegister) {
            confirmPasswordFieldRef.current.value = '';
            // setErrors({
            //     ...errors,
            //     confirmPasswordError: '',
            // });
        }
    }

    const onFieldEntered = (e, fieldRef) => {
        clearErrors();
        fieldRef.current.value = e.target.value;
    }

    const isEmailValid = (email) => {
        var re = utils.emailRegex;
        return re.test(email);
    }

    const isPasswordValid = (password) => {
        var re = utils.passwordRegex;
        return re.test(password)
    }

    const switchMode = () => {
        clearAllValuesAndErrors();
        setDisplayRegister(!displayRegister);
    }

    const submitForm = async (isRegistration) => {
        if (isRegistration) {
            await register();
        } else {
            await login();
        }
    }

    const login = async () => {
        let emailValue = emailFieldRef.current.value;
        let passwordValue = passwordFieldRef.current.value;
        let emailValid = isEmailValid(emailValue);
        let passwordValid = isPasswordValid(passwordValue);

        if (!emailValid) {
            setErrors({...errors, emailError: 'Please enter a valid email.'});
            return;
        }

        if (!passwordValid) {
            setErrors({...errors, passwordError: "Password must be >8 characters long."});
            return;
        }

        if (emailValid && passwordValid) {
            setIsLoading(true);
            const loginUser = { email: emailValue, password: passwordValue }
            try {
                const loginResponse = await obeliskApiService.login(loginUser);
                if (loginResponse.data.status == "unconfirmed") {
                    console.log("unconfirmedd");
                    setIsUnconfirmed(true);
                    setGeneralMessage("A confirmation email has been send to your address.")
                    const emailSent = await obeliskApiService.sendEmailVerification({ email: emailValue });
                    setIsLoading(false);
                    console.log("Verification upon login sent");
                    return;
                }

                console.log("login response", loginResponse);
                setUserData({
                    token: loginResponse.data.accessToken,
                    userId: loginResponse.data.userId,
                    email: loginResponse.data.email,
                    userName: loginResponse.data.userName,
                    walletAddress: loginResponse.data.walletAddress,
                    isIssuer: loginResponse.data.isIssuer,
                    issuerName: loginResponse.data.issuerName,
                    issuerWebsite: loginResponse.data.issuerWebsite,
                    issuerContactEmail: loginResponse.data.issuerContactEmail,
                    documentBalance: loginResponse.data.documentBalance,
                });

                localStorage.setItem("auth-token", loginResponse.data.accessToken);
                setAuthHeader(loginResponse.data.accessToken);
                setUserLoggedIn(true);
                setIsLoading(false);
                navigate('/dashboard');
            } catch (error) {
                setIsLoading(false);
                console.log(error);
                error.response.data.message && setErrors({...errors, error: error.response.data.message});
            }
        }
    }

    const register = async () => {
        let emailValue = emailFieldRef.current.value;
        let passwordValue = passwordFieldRef.current.value;
        let confirmPasswordValue = confirmPasswordFieldRef.current.value;
        let emailValid = isEmailValid(emailValue)
        let passwordValid = isPasswordValid(passwordValue);
        let passwordCheckValid = passwordValue == confirmPasswordValue;

        console.log(errors);

        console.log(userType);
        console.log(emailValid)
        if (!emailValid) {
            setErrors({...errors, emailError: 'Please enter a valid email.'});
            return;
        }

        if (!passwordValid) {
            setErrors({...errors, passwordError: 'Please enter a valid password.'});
            return;
        }

        if (!passwordCheckValid) {
            setErrors({...errors, confirmPasswordError: 'Please repeat your password.'});
            return;
        }

        console.log(emailValid, passwordValid, passwordCheckValid);

        if (emailValid && passwordValid && passwordCheckValid) {
            setIsLoading(true);
            const userToRegister =
            {
                email: emailValue,
                password: passwordValue,
                secretKey: null,
                passwordCheck: confirmPasswordValue,
                isIssuer: userType == 'issuer',
            }

            if (userType == 'issuer') {
                //userToRegister.issuerName = issuerNameRef.current;
            }

            try {
                const registerResponse = await obeliskApiService.register(userToRegister);
                console.log(registerResponse);
                if (registerResponse.data.successful) {
                    //setRegisterSuccessMessage("Registration successful.");
                    console.log("Registration successful.");
                    setGeneralMessage("A confirmation email has been sent to your address.")
                    setIsLoading(false);
                } else {
                    setErrors({...errors, error: 'Registration failed, please try again in a few moments.'});
                    setIsLoading(false);
                }
            } catch (error) {
                setIsLoading(false);
                console.log(error);
                error.response.data.message && setErrors({...errors, error: error.response.data.message});
            }
        }
    }

    const forgotPassword = async () => {
        const emailValue = emailFieldRef.current.value;
        if (!isEmailValid(emailValue)) {
            setErrors({...errors, emailError: 'Please enter your email address.'});
            return;
        }

        await obeliskApiService.sendForgotPasswordLink({ email: emailValue });
        setGeneralMessage("Instructions on how to reset your password have been sent to your email address.");
    }

    return (
        <PolyContainer>
            <MainContainer>
                <LeftSegment>
                    <img className="img logo" src={HDLogo}></img>
                    <img className="img pic" src={LandingImg}></img>
                </LeftSegment>
                <RightSegment>
                    <RegisterSegmentContainer>
                        <h5>{displayRegister ? "Already have an account?" : "Don't have an account?"}</h5>
                        <CustomButtonV2 onClick={() => switchMode()}>{displayRegister ? "Login" : "Register"}</CustomButtonV2>
                    </RegisterSegmentContainer>
                    <br />
                    <LandingTextContainer>
                        {/* <h3>Hello! Welcome to DocUnique.</h3> */}
                        {/* <h5>Please enter your credentials.</h5> */}
                    </LandingTextContainer>
                    <FormContainer>
                        <h3 className="mobilelbl">{displayRegister ? "Register" : "Log in"}</h3>
                        <LandingPageFieldV2>
                            <label>Email address</label>
                            <input ref={emailFieldRef} autoComplete="landing-email" onChange={(e) => onFieldEntered(e, emailFieldRef)}></input>
                        </LandingPageFieldV2>
                        {errors.emailError && <ErrorLabel>{errors.emailError}</ErrorLabel>}
                        <LandingPageFieldV2>
                            <LabelContainer>
                                <label>Password
                                    {
                                        displayRegister &&
                                        <Tooltip style={{color: '#205388', fontSize:'1.2rem'}} title={<p style={{padding: 0, margin: 0, fontSize: '0.9rem'}}>Password must contain at least one capital letter, at least one number, and be 8 or more characters long.</p>} placement="right">
                                            <InfoIcon></InfoIcon>
                                        </Tooltip>
                                    }
                                    
                                </label>
                                {
                                    !displayRegister &&
                                    <label className="forgot-label"><a href="#" onClick={() => forgotPassword()}>Forgot password?</a></label>
                                }
                            </LabelContainer>
                            <input type="password" ref={passwordFieldRef} autoComplete="landing-pw" onChange={(e) => onFieldEntered(e, passwordFieldRef)}></input>
                        </LandingPageFieldV2>
                        {errors.passwordError && <ErrorLabel>{errors.passwordError}</ErrorLabel>}
                        {
                            displayRegister &&
                            <>
                                <LandingPageFieldV2>
                                    <label>Confirm password</label>
                                    <input ref={confirmPasswordFieldRef} autoComplete="landing-pw-confirm" type="password" onChange={(e) => onFieldEntered(e, confirmPasswordFieldRef)}></input>
                                </LandingPageFieldV2>
                            </>
                        }
                        {errors.confirmPasswordError && <ErrorLabel>{errors.confirmPasswordError}</ErrorLabel>}
                        {
                            userType == "issuer" &&
                            <LandingPageFieldV2>
                                <label>Issuer name</label>
                                <input></input>
                            </LandingPageFieldV2>
                        }

                        {errors.error && <ErrorLabel>{errors.error}</ErrorLabel>}
                        <br />
                        {
                            isLoading ? (
                                <CustomLoader />
                            ) : (
                                <>
                                    {
                                        generalMessage ? (
                                            <GeneralMessage>{generalMessage}</GeneralMessage>
                                        ) : (
                                            <CustomButtonV2 type="button" onClick={() => submitForm(displayRegister)}>{displayRegister ? "Register" : "Login"}</CustomButtonV2>
                                        )
                                    }

                                </>
                            )

                        }
                        {/* {
                        !isLoading && generalMessage &&
                        <GeneralMessage>{generalMessage}</GeneralMessage>
                    } */}
                    </FormContainer>
                </RightSegment>
            </MainContainer>
        </PolyContainer>
    )
}

export default LandingPageV3

const MainContainer = styled.div`
    background:url(${LandingPolygons});

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    @media only screen and (min-width: 350px) and (max-width: 580px) {
        /* display:flex;
        flex-direction: column;
        height:100vh;
        justify-content: initial; */
        height: 100vh;
    }

    @media only screen and (min-width: 1100px) {
        /* justify-content: center;
        gap: 10rem; */
    }
`

const PolyContainer = styled.div`
    background: linear-gradient(180deg, #103B67 3.65%, #163a5f 100%);
    margin: 0;
    padding:0;
`

const LeftSegment = styled.div`
    
    position: relative;
    //width: 50%;
    height: 100vh;
    @media only screen and (min-width: 350px) and (max-width: 580px) {
        width: 100%;
        height: 8.25rem;
        background: none;
    }
    @media only screen and (min-width: 2000px) {
        gap: 11rem;
    }
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    .logo{
        align-self: start;
        @media only screen and (min-width: 350px) and (max-width: 580px) {
            margin-top: 20px;
            margin-left: 20px;
        }

        @media only screen and (min-width: 2000px) {
            //height: 7.25rem;
            margin-top: 3rem;
        }

        
        //min-height:100px;
        height: 3.25rem;
        margin-left: 50px;
        margin-top: 20px;
    }

    .pic{
        //min-height: 300px;
        height: 30rem;
        margin-left: 10.5rem;
        filter: drop-shadow(5px 5px 5px #222);

        @media only screen and (min-width: 350px) {
            //content: url(${LandingImgMobile});
            margin: 0;
            height: 19rem;
        }

        @media only screen and (min-width: 360px) {
            //content: url(${LandingImgMobile});
            margin-top: 20px;
            height: 28rem;
        }
        // IPHONE SE
        @media only screen and (min-width: 375px) {
            //content: url(${LandingImgMobile});
            margin-top: 10px;
            height: 17rem;
        }

        @media only screen and (min-width: 375px) and (min-height: 800px) {
            //content: url(${LandingImgMobile});
            margin-top: 20px;
            height: 30rem;
        }

        @media only screen and (min-width: 380px) and (max-width: 500px) {
            //content: url(${LandingImgMobile});
            margin-top: 30px;
            height: 33rem;
        }

        @media only screen and (min-width: 580px) and (max-width: 1100px){
            height: 35rem;
            margin-top: 30%;
            margin-left: 4rem;
        }

        @media only screen and (min-width: 1100px) and (max-width: 2000px){
            margin: 0;
            margin-left: 10rem;
            height: 35rem;
        }
        
        @media only screen and (min-width: 2000px) {
            height: 47rem;
            margin-left: 20.5rem;
        }
    }
    
`

const GeneralMessage = styled.div`
    width: 90%;
    margin-top: 20px;
    align-self: center;
    font-size: 1rem;
`
const FormContainer = styled.form`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items:center;
    padding-bottom: 50px;
    .mobilelbl{
        display: none;
    }
    @media only screen and (min-width: 350px) and (max-width: 580px) {
        padding: 0;
        padding-top: 30px;
        padding-bottom: 30px;
        .mobilelbl{
            display: block;
        }
    }

    @media only screen and (min-width: 1100px) {
        padding-bottom: 30px;
    }

    >h3{
        align-self: start;
        margin-left: 20px;
        margin-bottom: 20px;
        font-weight: bold;
        font-size: 1.8rem;
    }

    >button{
        width: 90%;
        height: 3.125rem;
        background-color: #205388;
        box-shadow: 0px 4px 7px 2px rgba(0, 0, 0, 0.25);
    }
`

const RightSegment = styled.div`
    box-shadow: 3px 4px 13px 9px rgba(0, 0, 0, 0.25);
    padding: 30px;
    border-radius: 20px;
    align-self: center;
    background: white;
    height: 37.5rem;
    width: 31.25rem;
    @media only screen and (min-width: 350px) and (max-width: 580px) {
        margin: 0;
        padding: 20px;
        align-self: end;
        height: auto;
        flex-direction: column-reverse;
        border-radius: 20px 20px 0px 0px;
        width: 100%;
    }

    @media only screen and (min-width: 700px) {
        justify-content: center;
        margin-right: 6rem;
    }

    @media only screen and (min-width: 1100px) {
        justify-content: center;
        margin-right: 10rem;
    }

    @media only screen and (min-width: 1500px) {
        justify-content: initial;
        height: 43.5rem;
        width: 37.25rem;
    }

    @media only screen and (min-width: 2000px) {
        justify-content: initial;
        height: 46.5rem;
        width: 40.25rem;
    }
    //width: 600px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 12.5rem;
`

const LandingPageFieldV2 = styled.div`
    display: flex;
    flex-direction: column;
    width: 90%;
    
    margin-bottom: 0.938rem;

    label{
        font-size: 1rem;
        @media only screen and (min-width: 350px) and (max-width: 580px) {
            font-size: 1rem;
        }
    }

    >input{
        -webkit-appearance: none;
        padding-left: 20px;
        height: 3.125rem;
        border: none;
        //box-shadow: 0px 4px 10px 3px rgba(0, 0, 0, 0.25);
        //box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
        border: 1px solid #205388;
        border-radius: 7px;
        font-size: 16px;
    }
`

const LandingTextContainer = styled.div`
    width: 90%;
    align-self: center;
    display: flex;
    flex-direction: column;
    >h3{
        font-size: 1rem;
        @media only screen and (min-width: 350px) and (max-width: 580px) {
            display: none;
        }
    }
    >h5{
        font-size: 1rem;
        @media only screen and (min-width: 350px) and (max-width: 580px) {
            display: none;
        }
    }
`

const RegisterSegmentContainer = styled.div`
    align-self: flex-end;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
    width: 90%;
    @media only screen and (min-width: 350px) and (max-width: 580px) {
        //margin-bottom: 5rem;
        align-self: center;
    }

   
    @media only screen and (min-width: 2000px) {
        //margin-bottom: 12rem;
    }

    >h5{
        font-size: 1rem;
    }
    >button{
        background-color: #205388;
        margin-left: 1.25rem;
        height: 3.125rem;
        width:  7rem;
        box-shadow: 0px 4px 7px 2px rgba(0, 0, 0, 0.25);
    }
`

const LabelContainer = styled.div`
    display: flex;
    flex-direction:row;
    width: 100%;
    justify-content: space-between;
    .forgot-label{
        >a{
            text-decoration: none;
            color: #205388;
        }
    }
`

const ErrorLabel = styled.div`
    align-self: center;
    color: red;
    font-size: 1rem;
    width: 90%;
`