import React, { useState, useContext, useRef } from 'react'
import { useNavigate } from 'react-router'
import PrimaryButton from '../components/buttons/CustomButtonV2'
import styled from 'styled-components'
import obeliskApiService from '../services/obeliskApiService'
import { ethers } from 'ethers'
import { abi } from '../smart_contract/abi.js'
import CryptoJs from 'crypto-js'
import UserContext from '../context/userContext';
import CustomLoader from './elements/CustomLoader'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import mixpanel from 'mixpanel-browser'

const IssueStep = (props) => {

    const { userData, setUserData } = useContext(UserContext);
    const [error, setError] = useState('')
    const [issueInProgress, setIssueInProgress] = useState(false)
    const [issueSuccessful, setIssueSuccessful] = useState(null)

    const CONTRACT_ABI = abi;
    const CONTRACT_ADDRESS = "0x57c8ADa1706F5B5960Aa8bA4b21dB5eFc8cC3bAe"

    const navigate = useNavigate()

    const { currentDocMetadata, documentFile, documentBase64 } = props

    const toastId = useRef("testToast");
    const inProgressToast = () => toastId.current = toast.loading("Issue in progress...", { type: toast.TYPE.INFO, autoClose: false })
    const successToast = () => toast.update(toastId.current, { render: "Document issued successfully!", type: toast.TYPE.SUCCESS, autoClose: 5000, isLoading: false })
    const errorToast = () => toast.update(toastId.current, { render: "Something went wrong", type: toast.TYPE.ERROR, autoClose: 5000, isLoading: false })

    const loadWalletFromPrivateKey = () => {
        try {
            //const newProvider = new ethers.EtherscanProvider('maticmum', "DXB1B3VDNY57T8K2F9F5PUCHV61MXKS8HE");
            const newProvider = new ethers.AlchemyProvider('maticmum', "_LSqNuoSwYcrWRs_pmty11AkPkcENjBU");
            console.log("provider", newProvider);
            console.log(process.env.REACT_APP_ADMIN_WALLET_PRIVATE_KEY);
            const wallet = new ethers.Wallet(process.env.REACT_APP_ADMIN_WALLET_PRIVATE_KEY, newProvider);
            console.log("masterwallet", wallet.address);
            return { wallet, newProvider };
        } catch (error) {
            console.log(error);
            return null;
        }
    }

    const issueDocumentToken = async () => {
        setIssueInProgress(true);
        inProgressToast();
        // Load admin wallet
        const { wallet, newProvider } = loadWalletFromPrivateKey();
        const contract = new ethers.Contract(
            CONTRACT_ADDRESS,
            CONTRACT_ABI,
            wallet
        )

        const ownerUser = await obeliskApiService.getUserByEmail(currentDocMetadata.ownerEmail);
        console.log("owner user", ownerUser);

        if (ownerUser) {
            console.log("user signal 1");
            let jsonObject = {
                name: currentDocMetadata.name,
                description: currentDocMetadata.description,
                ownerId: ownerUser.data._id,
                ownerEmail: ownerUser.data.email,
                ownerName: currentDocMetadata.ownerName,
                issuerId: userData.userId,
                issuerName: userData.issuerName,
                dateOfIssue: currentDocMetadata.dateOfIssue,
                expiryDate: currentDocMetadata.expiryDate,
                authorizedPerson: currentDocMetadata.authorizedPerson,
                documentFileBase64Hash: JSON.stringify(CryptoJs.SHA256(documentBase64).words),
            }

            let jsonString = JSON.stringify(jsonObject)
            console.log(jsonString);

            // encrypt the string with a key
            //const encryptionKey = process.env.REACT_APP_OBELISK_MASTER_KEY || "testMasterKey"
            const encryptionKey = CryptoJs.SHA256(jsonObject.documentFileBase64Hash).toString();
            console.log("encrypted with key", encryptionKey);
            let cipher = CryptoJs.AES.encrypt(jsonString, encryptionKey).toString();

            const sendOptions = {
                //value: ethers.utils.parseEther("0.011")
                //gasLimit: 5000000
            }

            // mint token with data
            // call the contract
            try {
                const stringBytes = ethers.toUtf8Bytes(cipher)
                const result = await contract.mint(ownerUser.data.walletAddress, 1, 1, stringBytes, sendOptions)
                if (result) {
                    console.log(result);
                    const receipt = await newProvider.waitForTransaction(result.hash, 1, 150000)
                    if (receipt) {
                        console.log("Tx is confirmed. Receipt.", receipt);
                        // proceed with document upload and data writing
                        const documentInsertedToDb = await issueDocument(result.hash, ownerUser.data._id);
                        console.log("doc inserted", documentInsertedToDb);
                        setIssueInProgress(false);
                        setIssueSuccessful(true);
                        successToast();

                        if(userData.documentBalance > 0){
                            setUserData({
                                ...userData,
                                documentBalance: userData.documentBalance - 1
                            })
                        }

                        let notificationObject = {
                            userName: ownerUser.data?.userName,
                            email: ownerUser.data?.email,
                            documentName: currentDocMetadata.name,
                            documentIssuerName: userData.issuerName,
                            documentDateOfIssue: currentDocMetadata.dateOfIssue
                        }

                        console.log(notificationObject);
                        mixpanel.track("Document issued", {
                            issuer_name: userData.issuerName,
                        })

                        await obeliskApiService.sendEmailNotification({ notificationObject });

                        // TODO: return a tx hash perhaps
                        console.log(documentInsertedToDb, result.hash);
                    }
                } else {
                    console.log("Something went wrong");
                    setError("Something went wrong.")
                    errorToast();
                }

            } catch (error) {
                console.log(error);
                alert("Something went wrong", error);
                errorToast();
            }

        } else {
            setError('That user does not exist in the system.');
        }
    }

    const issueDocument = async (txHash, ownerId) => {
        try {
            const documentToSend = {
                name: currentDocMetadata.name,
                description: currentDocMetadata.description,
                dateOfIssue: currentDocMetadata.dateOfIssue,
                expiryDate: currentDocMetadata.expiryDate,
                authorizedPerson: currentDocMetadata.authorizedPerson,
                ownerId: ownerId,
                ownerEmail: currentDocMetadata.ownerEmail,
                ownerName: currentDocMetadata.ownerName,
                blockchainHash: txHash,
                documentFileBase64Hash: JSON.stringify(CryptoJs.SHA256(documentBase64).words),
                issuerId: userData.userId,
                issuerName: userData.issuerName,
            }

            // First upload the file and get the fileId
            const fileUploadResponse = await obeliskApiService.uploadDocumentToDb(documentFile, currentDocMetadata.name)
            console.log("File upload response", fileUploadResponse);
            if (fileUploadResponse.data.successful) {
                console.log("File uploaded");
                // Proceed with metadata
                documentToSend.documentFileId = fileUploadResponse.data.fileId;
                const insertMetadataResponse = await obeliskApiService.insertDocumentMetadata(documentToSend)
                if (insertMetadataResponse.data.status == "success") {
                    console.log("Metadata inserted", insertMetadataResponse);
                } else {
                    console.log("Metadata failed to insert", insertMetadataResponse);
                }
            } else {
                console.log("File upload failed, aborting db operations.");
            }
        } catch (error) {
            console.log(error);
        }
    }

    const issueAgain = () => {
        props.setDocMetadata({
            name: null,
            description: null,
            dateOfIssue: null,
            expiryDate: null,
            authorizedPerson: null,
            userId: null,
            ownerEmail: null,
        });

        props.setPhase('upload');
    }

    return (
        <IssueStepContainer>
            <BoxFieldContainer>
                {
                    !issueInProgress && issueSuccessful == null &&
                    <>
                        <BoxField>
                            <p className="met-title">Document name:</p>
                            <p>{props.currentDocMetadata.name}</p>
                        </BoxField>
                        <BoxField>
                            <p className="met-title">Document description:</p>
                            <p>{props.currentDocMetadata.description}</p>
                        </BoxField>
                        <BoxField>
                            <p className="met-title">Date of issue:</p>
                            <p>{props.currentDocMetadata.dateOfIssue}</p>
                        </BoxField>
                        <BoxField>
                            <p className="met-title">Document expiry date:</p>
                            <p>{props.currentDocMetadata.expiryDate ?? "None"}</p>
                        </BoxField>
                        <BoxField>
                            <p className="met-title">Authorized person:</p>
                            <p>{props.currentDocMetadata.authorizedPerson}</p>
                        </BoxField>
                        <BoxField>
                            <p className="met-title">Document owner:</p>
                            <p>{props.currentDocMetadata.ownerEmail}</p>
                        </BoxField>
                        <br />
                        <PrimaryButton onClick={() => issueDocumentToken()} style={{ width: '100%' }}>Issue</PrimaryButton>
                    </>
                }
                {
                    issueSuccessful == true && !issueInProgress &&
                    <IssueMessageContainer>
                        <CheckCircleIcon style={{ color: 'green', height: '50px', width: '50px' }}></CheckCircleIcon>
                        <h3>Document issued successfully</h3>
                        <br />
                        <div style={{ display: 'flex', gap: '20px' }}>
                            <PrimaryButton
                                style={{ padding: '0px 20px 0px 20px' }}
                                onClick={() => issueAgain()}>Issue document</PrimaryButton>
                            <PrimaryButton
                                style={{ padding: '0px 20px 0px 20px' }}
                                onClick={() => navigate('/dashboard')}>Dashboard</PrimaryButton>
                        </div>
                    </IssueMessageContainer>
                }
                {/* {
                    issueInProgress && <CustomLoader style={{textAlign:'center'}}/>
                } */}
                {
                    
                    issueInProgress && 
                    <div style={{display: 'flex', flexDirection: 'column', justifyContent:'center'}}>
                        <HourglassBottomIcon style={{ color: '#1C6EA4', height: '50px', width: '50px', alignSelf: 'center' }}/>
                        <br />
                        <h2 style={{ textAlign: 'center' }}>Your document will be issued soon.</h2>
                    </div>
                }

                {
                    error && <ErrorLabel>{error}</ErrorLabel>
                }
            </BoxFieldContainer>
        </IssueStepContainer>
    )
}

export default IssueStep

const IssueMessageContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

const BoxField = styled.div`
    display: flex;
    //padding-left: 20px;
    //padding-right: 20px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    >p{
        margin: auto 0px;
    }
    height: 50px;
    background: white;
    margin-bottom: 10px;
`
const BoxFieldContainer = styled.div`
    .met-title{
        font-weight: bold;
    }
    
`
const IssueStepContainer = styled.div`
    box-shadow: rgba(0, 0, 0, 0.2) 0px 18px 50px -10px;
    border-radius: 20px;
    padding: 30px;   
    width: 35rem;
    max-width: 800px;
    margin-top: 40px;
    font-size: 1.2rem;
    margin-top: 5rem;
    @media only screen and (max-width: 1100px) {
        //margin-top: 120px;
    }
`
const ErrorLabel = styled.h5`
    color: red;
`