import React, { useEffect, useContext, useRef, useState } from 'react'
import PrimaryButton from '../buttons/CustomButtonV2'
import ArrowBack from '@mui/icons-material/ArrowBack';
import ArrowForward from '@mui/icons-material/ArrowForward';
import styled from 'styled-components'
import obeliskApiService from '../../services/obeliskApiService.js'
import UserContext from '../../context/userContext.js'
import CustomLoader from '../elements/CustomLoader.jsx'
import CustomTableV2 from '../elements/CustomTableV2';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

// TODO: Rename to dashboard
const MainAppPage = () => {
    const userContext = useContext(UserContext);
    const loggedIn = userContext.userData.userId !== undefined;
    const [filterConfig, setFilterConfig] = useState(null);
    const [showfilters, setShowFilters] = useState(false);

    const DOCS_PER_PAGE = 7;
    const STARTING_PAGE = userContext.userData.isIssuer ? 0 : 1;
    const DEFAULT_FETCH_OPTIONS = {
        pagingOptions: {
            page: STARTING_PAGE,
            docsPerPage: DOCS_PER_PAGE
        },
        filters: null
    }

    const [filteredDocuments, setFilteredDocuments] = useState([])
    const [userLoggedIn, setUserLoggedIn] = useState(false);
    const [listLoading, setListLoading] = useState(false);
    const [totalDocCount, setTotalDocCount] = useState(0);
    const [fetchOptions, setFetchOptions] = useState(
        {
            pagingOptions: {
                page: STARTING_PAGE,
                docsPerPage: DOCS_PER_PAGE
            },
            filters: null
        })

    const documentNameFilterRef = useRef(null);
    const documentIssueDateFilterRef = useRef(null);
    const documentOwnerFilterRef = useRef(null);
    const documentIssuerFilterRef = useRef(null);

    useEffect(() => {
        console.log("USER DATA ON DASHBOARD RENDER", userContext.userData)
        const getUserDocumentsByWalletAddress = async (walletAddress) => {
            console.log("entered method");
            const result = await obeliskApiService.getUserDocumentsByWalletAddress(walletAddress, fetchOptions);
            console.log("result from api", result);
            console.log("get user docs res", result);
            if (result) {
                //setUserDocuments(result.data);
                setFilteredDocuments(result.data.documentsList);
                setTotalDocCount(result.data.totalDocCount)
                setListLoading(false);
                console.log("api result", result.data);
            }
        }

        const getIssuerDocumentsByIssuerId = async (issuerId) => {
            console.log("entered method");
            const result = await obeliskApiService.getIssuerDocumentsByIssuerId(issuerId, fetchOptions);
            console.log("result from api", result);
            console.log("get user docs res", result);
            if (result) {
                //setUserDocuments(result.data);
                setFilteredDocuments(result.data.documentsList);
                setTotalDocCount(result.data.totalDocCount)
                setListLoading(false);
                console.log("api result", result.data.documentsList);
            }
        }

        if (userContext.userData.userId !== undefined) {
            setListLoading(true);
            setUserLoggedIn(true);
            //console.log("Logged in");
            if (userContext.userData.isIssuer != true) {
                getUserDocumentsByWalletAddress(userContext.userData.walletAddress);
            } else {
                getIssuerDocumentsByIssuerId(userContext.userData.userId)
            }
            console.log("we have documents", filteredDocuments);
        } else {
            console.log("Not authenticated");
        }

    }, [userContext.userData])


    const onFilterChange = (e, fieldRef) => {
        fieldRef.current.value = e.target.value;
    }

    // TODO: Filter (search) and sort need to be implemented on the backend first
    const search = async () => {
        const filterOptions = {}
        const name = documentNameFilterRef.current.value;
        console.log(name)
        const issuerName = !userContext.userData.isIssuer ? documentIssuerFilterRef.current.value : null;
        console.log(issuerName)

        const ownerName = userContext.userData.isIssuer ? documentOwnerFilterRef.current.value : null;
        console.log(ownerName)

        const issueDate = documentIssueDateFilterRef.current.value;
        console.log(issueDate)


        if (name) {
            filterOptions.name = name
        }
        if (issuerName) {
            filterOptions.issuerName = issuerName
        }
        if (ownerName) {
            filterOptions.ownerName = ownerName
        }
        if (issueDate) {
            filterOptions.dateOfIssue = issueDate
        }

        console.log(filteredDocuments);
        console.log("Filter options:", filterOptions);
        setListLoading(true);
        setFetchOptions({ ...DEFAULT_FETCH_OPTIONS, filters: filterOptions })
        let filteredData;
        if (!userContext.userData.isIssuer) {
            filteredData = await obeliskApiService.getUserDocumentsByWalletAddress(userContext.userData.walletAddress, { ...DEFAULT_FETCH_OPTIONS, filters: filterOptions });
        } else {
            filteredData = await obeliskApiService.getIssuerDocumentsByIssuerId(userContext.userData.userId, { ...DEFAULT_FETCH_OPTIONS, filters: filterOptions });
        }

        if (filteredData) {
            setFilteredDocuments(filteredData.data.documentsList);
            setTotalDocCount(filteredData.data.totalDocCount)
            setListLoading(false);
            console.log(filteredData, listLoading, filteredDocuments.length);
        }
    }

    const nextPage = async () => {
        let currentPage = fetchOptions.pagingOptions.page;
        setFetchOptions({ ...fetchOptions, pagingOptions: { page: currentPage + 1, docsPerPage: DOCS_PER_PAGE } })
        setListLoading(true);
        let nextPage;
        if (!userContext.userData.isIssuer) {
            nextPage = await obeliskApiService.getUserDocumentsByWalletAddress(userContext.userData.walletAddress, { ...fetchOptions, pagingOptions: { page: currentPage + 1, docsPerPage: DOCS_PER_PAGE } });
        } else {
            nextPage = await obeliskApiService.getIssuerDocumentsByIssuerId(userContext.userData.userId, { ...fetchOptions, pagingOptions: { page: currentPage + 1, docsPerPage: DOCS_PER_PAGE } });
        }
        if (nextPage) {
            setFilteredDocuments(nextPage.data.documentsList)
            setListLoading(false);
        }
    }

    const prevPage = async () => {
        let currentPage = fetchOptions.pagingOptions.page;
        setFetchOptions({ ...fetchOptions, pagingOptions: { page: currentPage - 1, docsPerPage: DOCS_PER_PAGE } })
        setListLoading(true);
        let prevPage;
        if (!userContext.userData.isIssuer) {
            prevPage = await obeliskApiService.getUserDocumentsByWalletAddress(userContext.userData.walletAddress, { ...fetchOptions, pagingOptions: { page: currentPage - 1, docsPerPage: DOCS_PER_PAGE } });
        } else {
            prevPage = await obeliskApiService.getIssuerDocumentsByIssuerId(userContext.userData.userId, { ...fetchOptions, pagingOptions: { page: currentPage - 1, docsPerPage: DOCS_PER_PAGE } });
        }
        if (prevPage) {
            setFilteredDocuments(prevPage.data.documentsList)
            setListLoading(false);
        }
    }

    return (
        <div>

            {/* <DetailsViewPage>

            </DetailsViewPage> */}

            <MainAppContentContainer>
                
                {showfilters &&
                    <FilterComponent>
                        <GroupedFields>
                            <MainPageFilterField>
                                <label>Document name</label>
                                <input ref={documentNameFilterRef} onChange={(e) => onFilterChange(e, documentNameFilterRef)} placeholder='None' ></input>
                            </MainPageFilterField>
                            <MainPageFilterField>
                                <label>Issue date</label>
                                <input type='date' ref={documentIssueDateFilterRef} onChange={(e) => onFilterChange(e, documentIssueDateFilterRef)}></input>
                            </MainPageFilterField>
                            <MainPageFilterField>
                                <label>{userContext.userData.isIssuer ? "Owner" : "Issuer"}</label>
                                {
                                    userContext.userData.isIssuer ?
                                        <input ref={documentOwnerFilterRef} onChange={(e) => onFilterChange(e, documentOwnerFilterRef)} placeholder='None' ></input> :
                                        <input ref={documentIssuerFilterRef} onChange={(e) => onFilterChange(e, documentIssuerFilterRef)} placeholder='None' ></input>
                                }
                            </MainPageFilterField>
                        </GroupedFields>
                        {/* <MainPageFilterFieldCB>
                        <label>Hide expired</label>
                        <input type="checkbox" ref={searchInputRef} onChange={(e) => updateRef(e)} placeholder='None' ></input>
                    </MainPageFilterFieldCB> */}
                        <PrimaryButton onClick={() => search()}>Search</PrimaryButton>
                    </FilterComponent>
                }
                {
                    <ToggleFilterButton filterShown={showfilters} onClick={() => setShowFilters(!showfilters)}>
                        {showfilters ? "Hide filters" : "Show filters"}
                        {
                            showfilters ? <ArrowDropUpIcon style={{color: '#163a5f', margin: '0'}}></ArrowDropUpIcon> :
                            <ArrowDropDownIcon style={{color: '#163a5f', margin: '0'}}></ArrowDropDownIcon> 
                        }
                    </ToggleFilterButton>
                }


                {/* <FilterSortComponent>
                        <PrimaryButton>Sort <SortIcon /></PrimaryButton>
                        <PrimaryButton>Filter <FilterAltIcon /></PrimaryButton>
                    </FilterSortComponent> */}


                <br />
                <br />
                {
                    listLoading && <CustomLoader />
                }
                {
                    !listLoading && filteredDocuments?.length == 0 &&
                    <p style={{ marginTop: '100px', fontSize: "20px", textAlign: 'center' }}>No documents</p>
                }
                {
                    !listLoading && filteredDocuments?.length > 0 &&
                    <CustomTableV2 documents={filteredDocuments} isIssuer={userContext.userData.isIssuer}></CustomTableV2>
                }
                {
                    filteredDocuments.length > 0 &&
                    <>
                        <PageCounterContainer>
                            <PrimaryButton disabled={fetchOptions.pagingOptions.page == STARTING_PAGE} onClick={() => prevPage()}><ArrowBack /></PrimaryButton>
                            <p>{userContext.userData.isIssuer ? fetchOptions.pagingOptions?.page + 1 : fetchOptions.pagingOptions?.page}</p>
                            <PrimaryButton
                                disabled={
                                    !userContext.userData.isIssuer ?
                                        fetchOptions.pagingOptions.page * fetchOptions.pagingOptions.docsPerPage >= totalDocCount :
                                        (fetchOptions.pagingOptions.page + 1) * fetchOptions.pagingOptions.docsPerPage >= totalDocCount}
                                onClick={() => nextPage()}><ArrowForward /></PrimaryButton>
                        </PageCounterContainer>
                        <br />
                        <p>Total results: {totalDocCount}</p>
                    </>

                }
            </MainAppContentContainer>
        </div>
    )
}
export default MainAppPage

const ToggleFilterButton = styled.div`

    margin-top: ${props => props.filterShown ? '25px' : '0px' };
    width: 100%;
    max-width: 70vw;
    @media only screen and (min-width: 350px) and (max-width: 580px) {
        max-width: 85vw;
    }
    padding: 5px;
    text-align: center;
    background-color: #D3D3D3;
    border-radius: 10px;
    color: #003459;
    cursor: pointer;
    :hover{
        opacity: 0.7;
    }
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
`
const MainPageFilterFieldCB = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`

const MainPageFilterField = styled.div`
    display: flex;
    flex-direction: column;
    
    margin-bottom: 0.938rem;

    label{
        font-size: 1rem;
        @media only screen and (min-width: 350px) and (max-width: 580px) {
            font-size: 1rem;
        }
    }

    input{
        -webkit-appearance: none;
        height: 3rem;
        padding-left: 10px;
        border-radius: 10px;
        border: 1px solid gray;
        padding-left: 15px;
        padding-right: 15px;
    }
`


const MainAppContentContainer = styled.div`
    padding-top: 50px;
    @media only screen and (max-width: 1100px) {
        padding-top: 100px;
    }
    @media only screen and (min-width: 1100px) {
        margin-left: 20%;
    }
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

`

const PageCounterContainer = styled.div`
    margin-top: 1.75rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    >p{
        
        padding: 0;
        margin: 0 1.25rem;
    }

    >button{
        width: 50px;
    }
`

const GroupedFields = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    input{
        width: 18.75rem;
    }

    @media only screen and (min-width: 320px) and (max-width: 580px) {
        justify-content: center;
        input{
            width: 85vw;
        }
    }
    gap:10px;
    justify-content: space-between;
    
       
`
const FilterComponent = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 70vw;

    @media only screen and (min-width: 350px) and (max-width: 580px) {
        max-width: 85vw;
        button{
            margin-top: 15px;
        }
    }
`
const FilterSortComponent = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 20px;
    button{
        width: 100%;
    }
`

const FilterBar = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    /* input{
        background: #003459;
        color: #ffffff;
        border: none;
        border-radius: 5px;
        width: 100%;
        max-width: 58vw;
        height: 50px;
        padding: 5px;
        ::placeholder{
            color: #ffffff;
            opacity: 1;
        }
    } */

`



