import styled from "styled-components";
import React, {useEffect, useRef, useState} from 'react'

const DocumentMetadataInputField = (props) => {
    const [inputType] = useState(props.type)
    const [inputMinVal] = useState(props.min)
    const [inputValue, setInputValue] = useState('')

    // useEffect(() => {
    //     if(props.valueChanged) props.valueChanged(inputValue)
    // }, [inputValue])
    

    function handleChange(event){
        console.log(event.target.value);
        setInputValue(event.target.value);
        if(props.valueChanged) props.valueChanged(event.target.value)
      }
    

    return (
        <DocumentMetadataInputFieldContainer>
            {/* TODO: Error handling */}
            <InputLabel>{props.fieldName}</InputLabel>
            <input style={{width:'53%', height:'3rem'}} type={inputType} value={inputValue} onChange={handleChange} min={inputMinVal}></input>
        </DocumentMetadataInputFieldContainer>
    )
}

export default DocumentMetadataInputField

const DocumentMetadataInputFieldContainer = styled.div`
    background: white;
    display: flex;
    margin-bottom: 15px;
    font-size: 1.1rem;
    height: 2.5rem;
    width: 100%;
    justify-content: space-between;
    align-content: center;
    > p {
        margin: auto 0px;
    }
    //cursor: pointer;
    >input{
        padding-left: 2rem;
        font-size: 16px;
    }
`

const InputLabel = styled.p`
    padding: 10px;
`