import React, { useState, useRef, useContext, useEffect } from 'react'
import styled from 'styled-components'
import PrimaryButton from '../buttons/CustomButtonV2'
import UserContext from '../../context/userContext';
import obeliskApiService from '../../services/obeliskApiService';
import PersonIcon from '@mui/icons-material/Person';
import { utils } from '../../helpers/utils';
import ShieldIcon from '@mui/icons-material/Shield';
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';


const SettingsPage = () => {
    const { token, userData, setUserData } = useContext(UserContext);

    const [showChangePwModal, setShowPwModal] = useState(false);
    const [canUpdateSettings, setCanUpdateSettings] = useState(false);
    const [emailErrorMessage, setEmailErrorMessage] = useState(null);
    const [userNameErrorMessage, setUserNameErrorMessage] = useState(null);
    const [issuerNameErrorMessage, setIssuerNameErrorMessage] = useState(null);
    const [issuerContactEmailErrorMessage, setIssuerContactEmailErrorMessage] = useState(null);
    const [issuerWebsiteEmailErrorMessage, setIssuerWebsiteEmailErrorMessage] = useState(null);
    const [updateError, setUpdateError] = useState(null);
    const [updateSuccessMessage, setUpdateSuccessMessage] = useState(null);
    const [oldPasswordError, setOldPasswordError] = useState(null);
    const [newPasswordError, setNewPasswordError] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);
    const oldPwRef = useRef();
    const newPwRef = useRef();
    const emailRef = useRef();
    const userNameRef = useRef();
    const issuerNameRef = useRef();
    const issuerWebsiteRef = useRef();
    const issuerContactEmailRef = useRef();

    useEffect(() => {
        console.log("rendererd", userData);
        emailRef.current.value = userData.email;
        if (userData.isIssuer) {
            issuerNameRef.current.value = userData?.issuerName ?? '';
            issuerWebsiteRef.current.value = userData?.issuerWebsite ?? '';
            issuerContactEmailRef.current.value = userData?.issuerContactEmail ?? '';
            console.log(issuerWebsiteRef.current.value);
        } else {
            userNameRef.current.value = userData?.userName ?? "";
        }
        console.log("start", emailRef.current.value);

    }, [userData])


    const handlePwChangeClose = () => {
        oldPwRef.current.value = '';
        newPwRef.current.value = '';
        clearPwErrors();
        setShowPwModal(false);
    }

    const onOldPwEntered = (e) => {
        clearPwErrors();
        oldPwRef.current.value = e.target.value;
    }

    const onNewPwEntered = (e) => {
        clearPwErrors();
        newPwRef.current.value = e.target.value;
    }

    const onEmailChanged = (e) => {
        emailRef.current.value = e.target.value;
        console.log(emailRef.current.value);
        checkIfCanUpdate();
    }

    const onIssuerNameChanged = (e) => {
        issuerNameRef.current.value = e.target.value;
        checkIfCanUpdate();
    }

    const onIssuerContactEmailChanged = (e) => {
        issuerContactEmailRef.current.value = e.target.value;
        checkIfCanUpdate();
    }

    const onIssuerWebsiteChanged = (e) => {
        issuerWebsiteRef.current.value = e.target.value;
        console.log("website changed");
        console.log(issuerWebsiteRef.current.value);
        checkIfCanUpdate();
    }

    const onUserNameChanged = (e) => {
        userNameRef.current.value = e.target.value;
        checkIfCanUpdate();
    }

    const checkIfCanUpdate = () => {
        setUpdateSuccessMessage(null);
        const validFieldsIssuer = {
            email: false,
            issuerName: false,
            issuerContactEmail: false,
            issuerWebsite: false,
        }

        const validFieldsNonIssuer = {
            email: false,
            userName: false,
        }

        const originalStatusIssuer = {
            email: true,
            issuerName: true,
            issuerContactEmail: true,
            issuerWebsite: true,
        }

        const originalStatusNonIssuer = {
            email: true,
            userName: true,
        }

        const { userName, email, issuerName, issuerContactEmail, issuerWebsite, isIssuer } = userData;

        const emailCurrent = emailRef.current.value.trim();
        console.log("emailcurrent", emailCurrent);

        if (emailCurrent != "") {
            if (isIssuer) {
                validFieldsIssuer.email = true;
            } else {
                validFieldsNonIssuer.email = true;
            }
        }

        if (emailCurrent !== email) {
            if (isIssuer) {
                originalStatusIssuer.email = false;
            } else {
                originalStatusNonIssuer.email = false;
            }
        }

        if (isIssuer) {
            const issuerNameCurrent = issuerNameRef.current.value.trim();
            const issuerWebsiteCurrent = issuerWebsiteRef.current.value.trim();
            const issuerContactEmailCurrent = issuerContactEmailRef.current.value.trim();

            if (issuerNameCurrent != "") {
                validFieldsIssuer.issuerName = true;
            }

            if (issuerNameCurrent !== issuerName) {
                originalStatusIssuer.issuerName = false;
            }

            if (issuerContactEmailCurrent != "") {
                validFieldsIssuer.issuerContactEmail = true;
            }

            if (issuerContactEmailCurrent !== issuerContactEmail) {
                originalStatusIssuer.issuerContactEmail = false;
            }

            if (issuerWebsiteCurrent != "") {
                validFieldsIssuer.issuerWebsite = true;
            }

            if (issuerWebsiteCurrent !== issuerWebsite) {
                originalStatusIssuer.issuerWebsite = false;
            }
        } else {
            const userNameCurrent = userNameRef.current.value.trim();

            if (userNameCurrent != "") {
                validFieldsNonIssuer.userName = true;
            }

            if (userNameCurrent !== userName) {
                originalStatusNonIssuer.userName = false;
            }
        }


        console.log(validFieldsIssuer);
        console.log(originalStatusIssuer);

        //console.log(validFieldsNonIssuer);
        //console.log(originalStatusNonIssuer);

        if (!isIssuer) {
            const defaultValid = Object.values(validFieldsNonIssuer).every(Boolean);
            const defaultChanged = !Object.values(originalStatusNonIssuer).every(Boolean)
            setCanUpdateSettings(defaultValid && defaultChanged)
        } else {
            const issuerValid = Object.values(validFieldsIssuer).every(Boolean);
            const issuerChanged = !Object.values(originalStatusIssuer).every(Boolean)
            setCanUpdateSettings(issuerValid && issuerChanged)
        }
    }

    const updateUserSettings = async () => {
        console.log("HIIII")
        console.log(emailRef.current.value);

        let emailValid = isEmailValid(emailRef.current.value);
        let userNameValid = isUserNameValid(userNameRef?.current?.value);
        let issuerNameValid = isUserNameValid(issuerNameRef?.current?.value);
        let issuerContactEmailValid = isEmailValid(issuerContactEmailRef?.current?.value);
        let issuerWebsiteValid = isWebsiteValid(issuerWebsiteRef?.current?.value);

        if (!emailValid) {
            setEmailErrorMessage("Email is not in a valid format.");
            console.log("email bad")
        }

        if (!userNameValid) {
            setUserNameErrorMessage("Username is not in a valid format.")
            console.log("username bad")
        }

        if (userData.isIssuer) {
            if (!issuerWebsiteValid) {
                setIssuerWebsiteEmailErrorMessage("Website should be in the following format: 'https://something.com'");
                console.log("website bad")
            }

            if (!issuerContactEmailValid) {
                setIssuerContactEmailErrorMessage("Email is not in a valid format.");
                console.log("issuer contact email bad")
            }

            if (!issuerNameValid) {
                setIssuerNameErrorMessage("Name is not in a valid format.")
                console.log("issuer name bad")
            }
        }

        console.log("Almost");
        let requestUpdate = false;

        if (emailValid && userNameValid) {
            console.log("username and email valid");
            if (userData.isIssuer) {
                console.log("is issuer")
                if (issuerNameValid && issuerContactEmailValid && issuerWebsiteValid) {
                    requestUpdate = true;
                }
            } else if (!userData.isIssuer) {
                console.log("is not issuer");
                requestUpdate = true;
            }
        }

        console.log("request update", requestUpdate)

        if (requestUpdate) {
            console.log("all good");
            try {
                const result = await obeliskApiService.updateUserSettings({
                    userId: userData.userId,
                    email: emailRef.current.value,
                    userName: userNameRef.current?.value ?? null,
                    issuerName: issuerNameRef.current?.value ?? null,
                    issuerContactEmail: issuerContactEmailRef.current?.value ?? null,
                    issuerWebsite: issuerWebsiteRef.current?.value ?? null,
                });

                console.log(result);
                if (result.data.successful) {
                    clearErrors();
                    setUpdateSuccessMessage("User settings updated successfully.");
                    setCanUpdateSettings(false);
                }

            } catch (error) {
                console.log(error);
                error.response.data.message && setUpdateError(error.response.data.message);
            }
        }
    }

    const changePassword = async () => {
        clearPwErrors();
        let oldPasswordValid = isPasswordValid(oldPwRef.current.value);
        let newPasswordValid = isPasswordValid(newPwRef.current.value);

        if (!oldPasswordValid) {
            setOldPasswordError("Password is not in a correct format.")
        }

        if (!newPasswordValid) {
            setNewPasswordError("Password is not in a correct format.")
        }

        if (oldPwRef.current.value === newPwRef.current.value) {
            setNewPasswordError("New password must be different than the old one.")
        }

        if (oldPasswordValid && newPasswordValid && oldPwRef.current.value !== newPwRef.current.value) {
            clearPwErrors();
            console.log("All good");
            try {
                const result = await obeliskApiService.changePassword({ oldPassword: oldPwRef.current.value, newPassword: newPwRef.current.value, userId: userData.userId });
                if (result.data.successful) {
                    setSuccessMessage("Password changed successfully.");
                }
            } catch (error) {
                console.log(error);
                error.response.data.message && setNewPasswordError(error.response.data.message);
            }
        }
    }

    const clearErrors = () => {
        setEmailErrorMessage(null);
        setUserNameErrorMessage(null);
        setIssuerWebsiteEmailErrorMessage(null);
        setIssuerContactEmailErrorMessage(null);
        setIssuerNameErrorMessage(null);
    }

    const clearPwErrors = () => {
        setOldPasswordError(null);
        setNewPasswordError(null);
    }

    const isWebsiteValid = (website) => {
        var re = utils.websiteRegex;
        return re.test(website);
    }

    const isEmailValid = (email) => {
        var re = utils.emailRegex;
        return re.test(email);
    }

    const isPasswordValid = (password) => {
        var re = utils.passwordRegex;
        return re.test(password)
    }

    const isUserNameValid = (username) => {
        var re = utils.userNameRegex;
        return re.test(username);
    }

    const changePasswordModalStyle = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            width: '28.175rem',
            transform: window.innerWidth > 1100 ? 'translate(-20%, -50%)' : 'translate(-50%, -50%)',
            background: '#205388',
            borderRadius: '10px'
        }
    }

    return (
        <MainAppContentContainer>
            <PersonalDetailsContainer>
                <h2><PersonIcon style={{ fontSize: '3rem', color: '#163a5f' }} />{userData.isIssuer ? "Issuer" : "Personal"} details</h2>
                <br />
                <CustomSettingsField>
                    <label>Email:</label>
                    <input ref={emailRef} onChange={(e) => onEmailChanged(e)} autoComplete="settings-email"></input>
                </CustomSettingsField>
                {emailErrorMessage && <ErrorMessage>{emailErrorMessage}</ErrorMessage>}
                {
                    userData.isIssuer ? (
                        <>
                            <CustomSettingsField>
                                <label>Issuer name:</label>
                                <input ref={issuerNameRef} onChange={(e) => onIssuerNameChanged(e)} autoComplete="settings-issuername"></input>
                            </CustomSettingsField>
                            {emailErrorMessage && <ErrorMessage>{issuerNameErrorMessage}</ErrorMessage>}
                            <CustomSettingsField>
                                <label>Issuer website:</label>
                                <input ref={issuerWebsiteRef} onChange={(e) => onIssuerWebsiteChanged(e)} autoComplete="settings-website"></input>
                            </CustomSettingsField>
                            {emailErrorMessage && <ErrorMessage>{issuerWebsiteEmailErrorMessage}</ErrorMessage>}
                            <CustomSettingsField>
                                <label>Info email:</label>
                                <input ref={issuerContactEmailRef} onChange={(e) => onIssuerContactEmailChanged(e)} autoComplete="settings-contactemail"></input>
                            </CustomSettingsField>
                            {emailErrorMessage && <ErrorMessage>{issuerContactEmailErrorMessage}</ErrorMessage>}
                        </>
                    ) : (
                        <>
                            <CustomSettingsField>
                                <label>User name:</label>
                                <input ref={userNameRef} onChange={(e) => onUserNameChanged(e)} autoComplete="settings-username"></input>
                            </CustomSettingsField>
                            {userNameErrorMessage && <ErrorMessage>{userNameErrorMessage}</ErrorMessage>}
                        </>
                    )
                }
                <br />
                <PrimaryButton type='button' disabled={!canUpdateSettings} onClick={() => updateUserSettings()}>Update settings</PrimaryButton>
                <br />
                {updateError && <ErrorMessage>{updateError}</ErrorMessage>}
                {updateSuccessMessage && <SuccessMessage>{updateSuccessMessage}</SuccessMessage>}
            </PersonalDetailsContainer>
            <PasswordContainer>
                <h2><ShieldIcon style={{ fontSize: '3rem', color: '#163a5f' }} /> Security details</h2>
                <br />
                <CustomSettingsField>
                    <label>Old password</label>
                    <input ref={oldPwRef} type="password" autoComplete="settings-old-password" onChange={(e) => onOldPwEntered(e)}></input>
                    {oldPasswordError && <ErrorMessage>{oldPasswordError}</ErrorMessage>}
                </CustomSettingsField>
                <CustomSettingsField >
                    <label>New password
                        <Tooltip style={{ color: '#205388', fontSize: '1.2rem' }} title={<p style={{ padding: 0, margin: 0, fontSize: '0.9rem' }}>Password must contain at least one capital letter, at least one number, and be 8 or more characters long.</p>} placement="right">
                            <InfoIcon></InfoIcon>
                        </Tooltip>
                    </label>
                    <input ref={newPwRef} type="password" autoComplete="settings-new-password" onChange={(e) => onNewPwEntered(e)}></input>
                    {newPasswordError && <ErrorMessage>{newPasswordError}</ErrorMessage>}
                </CustomSettingsField>
                <br />
                <PrimaryButton type='button' onClick={() => changePassword()}>Change password</PrimaryButton>
                <br />
                {successMessage && <SuccessMessage>{successMessage}</SuccessMessage>}
            </PasswordContainer>


            {/* <Modal
                isOpen={showChangePwModal}
                style={changePasswordModalStyle}
                onRequestClose={handlePwChangeClose}>
                <CloseIcon style={{ color: "#ffffff", cursor: 'pointer' }} onClick={handlePwChangeClose}></CloseIcon>
                <ModalContentContainer>
                    <CustomFormInput autocomplete="new-password">
                        <label>Old password</label>
                        <CustomInput ref={oldPwRef} type="password" autocomplete="new-password" onChange={(e) => onOldPwEntered(e)}></CustomInput>
                        {oldPasswordError && <ErrorMessage>{oldPasswordError}</ErrorMessage>}
                    </CustomFormInput>
                    <CustomFormInput autocomplete="new-password">
                        <label>New password</label>
                        <CustomInput ref={newPwRef} type="password" autocomplete="new-password" onChange={(e) => onNewPwEntered(e)}></CustomInput>
                        {newPasswordError && <ErrorMessage>{newPasswordError}</ErrorMessage>}
                    </CustomFormInput>
                    <br />
                    {successMessage && <SuccessMessage>{successMessage}</SuccessMessage>}
                    <PrimaryButton onClick={() => changePassword()}>Change password</PrimaryButton>
                </ModalContentContainer>

            </Modal> */}
        </MainAppContentContainer>
    )
}

export default SettingsPage

const PasswordContainer = styled.form`
    display: flex;
    flex-direction: column;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    padding: 20px;
    border-radius:10px;
`

const PersonalDetailsContainer = styled.form`
    display: flex;
    flex-direction: column;
    //border: 1px solid black;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    padding: 20px;
    border-radius:10px;
`

const ErrorMessage = styled.h5`
    padding-top: 10px;
    color: #EE4B2B;
    font-size: 1rem;
    align-self: start;
`

const SuccessMessage = styled.h5`
    color: #163a5f;
    font-size: 1rem;
    align-self: center;
`

const CustomFormInput = styled.div`
    input{
        margin-bottom: 10px;
        width: 23.875rem;
        display: block;
    }
    label{
        display: block;
        color: #ffffff;
    }
`

const MainAppContentContainer = styled.div`
    display:flex;
    flex-direction: row;
    justify-content: center;
    padding-top: 100px;
    gap: 200px;
    flex-wrap: wrap;
    @media only screen and (min-width: 1100px) {
        margin-left: 235px;
        gap:100px;
        padding-top: 50px;
    }
    @media only screen and (max-width: 1100px) {
        gap:20px;
    }

    /* padding-top: 30px;
    @media only screen and (max-width: 1100px) {
        padding-top: 100px;
    }
    @media only screen and (min-width: 1100px) {
        margin-left: 20%;
    }
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    > h3{
        margin-bottom: 5.25rem;
    }

    button{
        padding-left: 10px;
        padding-right: 10px;
    } */
`

const CustomFieldContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    justify-content: space-between;
    >p{
        margin: 0;
        font-size: 1rem;
    }
    >input{
        font-size: 1rem;
        width: 18rem;
    }
    max-width: 600px;
    width: 30rem;
    margin-bottom: 2rem;
`

const FieldsContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 2rem;
    flex-wrap: wrap;
`

const CustomSettingsField = styled.div`
    display: flex;
    flex-direction: column;
    width: 25rem;
    margin-bottom: 0.938rem;

    label{
        font-size: 1rem;
        @media only screen and (min-width: 350px) and (max-width: 580px) {
            font-size: 16px;;
        }
    }

    >input{
        -webkit-appearance: none;
        padding-left: 20px;
        height: 3.125rem;
        border: none;
        //box-shadow: 0px 4px 10px 3px rgba(0, 0, 0, 0.25);
        //box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
        border: 1px solid #205388;
        border-radius: 7px;
    }
`