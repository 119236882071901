import React, { useContext, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import DashboardIcon from '@mui/icons-material/Dashboard';
import SettingsIcon from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import UserContext from '../../context/userContext';
import DescriptionIcon from '@mui/icons-material/Description';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AppLogo from '../../assets/logohoriznew.png'
import styled from 'styled-components'
import $ from "jquery";

const CustomSideBarV2 = () => {

    const navigate = useNavigate();
    const { token, userData, setUserData } = useContext(UserContext);

    // State for UI only
    const [mobileLinksVisible, setMobileLinksVisible] = useState(false);

    const toggleMenu = () => {
        if (!mobileLinksVisible) {
            $('.sidebar').css({ display: 'block' });
            //$('.sidebar-logo').css({ display: 'none' });
            $('.top-navber').css({ display: 'none' });
            setMobileLinksVisible(true);
        } else {
            $('.sidebar').css({ display: 'none' });
            //$('.sidebar-logo').css({ display: 'block' });
            $('.top-navber').css({ display: 'flex' });
            setMobileLinksVisible(false);
        }
    }

    const logout = () => {
        setUserData({
            token: undefined,
            userId: undefined,
            isIssuer: undefined,
            issuerName: undefined,
            email: undefined,
            walletAddress: undefined,
        })
        //setUserLoggedIn(false);
        localStorage.setItem("auth-token", "");
        navigate('/')
    };

    return (
        <SideBarContainer>
            <div className="top-navbar">
                <a href="/dashboard"><img style={{ height: '3.5rem' }} src={AppLogo}></img></a>
                <MenuIcon onClick={toggleMenu} style={{ color: 'white', marginLeft: '10px', cursor: 'pointer', fontSize: '2rem' }} />
            </div>

            <div className="sidebar">
                <a href="/dashboard"><img className="sidebar-logo" src={AppLogo}></img></a>
                <br />
                <UsernameDisplay className="db-link">
                    <p>@{userData.isIssuer ? userData.issuerName : userData.userName}</p>
                </UsernameDisplay>
                {
                    userData.isIssuer == true &&
                    <>
                        <DocumentBalance>
                            <div>Token balance:</div>
                            <div><b>{userData?.documentBalance}</b>  <DescriptionIcon style={{ color: '#163a5f', width: '20', height: '20', paddingBottom: '2px' }} /></div>
                        </DocumentBalance>
                        <br />
                    </>
                }

                <Link to="/dashboard" className="sidebar-link"   >
                    <DashboardIcon style={{ color: '#ffffff', width: '30', height: '30' }} /> My dashboard
                </Link>
                {
                    userData.isIssuer == true &&
                    <Link to="/issue" className="sidebar-link">
                        <DescriptionIcon style={{ color: '#ffffff', width: '30', height: '30' }} /> Issue documents
                    </Link>
                }

                <Link to="/settings" className="sidebar-link" >
                    <SettingsIcon style={{ color: '#ffffff', width: '30', height: '30' }} /> Settings
                </Link>
                <a href="#" className="sidebar-link logout" onClick={() => logout()} >
                    <Logout style={{ color: '#ffffff', width: '30', height: '30' }} /> Log out</a>
            </div>
        </SideBarContainer>
        // <div>
        //     <link rel="stylesheet" href="https://www.w3schools.com/w3css/4/w3.css" />

        //     <div className="w3-sidebar w3-bar-block" style={SideBarStyle}>
        //         <br />
        //         {/* <h3 style={{ textAlign: 'center', color: '#ffffff' }}>Obelisk Project</h3> */}
        //         <img style={{height: '60px'}} src={AppLogo}></img>
        //         <br />
        //         <br />
        //         <Link to="/dashboard" className="w3-bar-item w3-button" style={{ color: '#ffffff' }}>
        //             <DashboardIcon style={{ color: '#ffffff', width: '30', height: '30' }} /> My dashboard
        //         </Link>
        //         {
        //             userData.isIssuer == true &&
        //             <Link to='/issue' className="w3-bar-item w3-button" style={{ color: '#ffffff' }}>
        //                 <DescriptionIcon style={{ color: '#ffffff', width: '30', height: '30' }} /> 
        //                 Issue documents
        //             </Link>
        //             // <a href="/issue" className="w3-bar-item w3-button" style={{ color: '#ffffff' }}>
        //             //     <DescriptionIcon style={{ color: '#ffffff', width: '30', height: '30' }} /> Issue documents
        //             // </a>
        //         }

        //         <a href="#" className="w3-bar-item w3-button" style={{ color: '#ffffff' }}>
        //             <SettingsIcon style={{ color: '#ffffff', width: '30', height: '30' }} /> Settings
        //         </a>
        //         <a href="#" onClick={() => logout()} style={{ position: 'absolute', bottom: 0, color: '#ffffff' }} className="w3-bar-item w3-button">
        //             <Logout style={{ color: '#ffffff', width: '30', height: '30' }} /> Log out</a>
        //     </div>
        // </div>
    )
}

export default CustomSideBarV2

const UsernameDisplay = styled.div`
    color: white;
    padding: 10px;
    margin-left: 2px;
    >p{
        font-size: 1.25rem;
        font-weight: bold;
        margin: 0;
        padding: 0;
    }
`

const DocumentBalance = styled.div`
    display: flex;
    background-color: white;
    color: #163a5f;
    padding: 15px;
    margin-left: 10px;
    margin-right: 10px;
    border-radius: 20px;
    //width: 75%;
    gap: 20px;
    justify-content: center;
    align-items: center;
`

const SidebarContainer = styled.div`
    ul {
        padding: 0;
        list-style-type: none;
    }

    .top_navbar{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 60px;
        background: #163a5f;
        box-shadow: 1px 0 2px rgba(0,0,0,0.125);
        display: flex;
        align-items: center;
    }

    .top_navbar .menu{
        width: calc(100% - 250px);
        padding: 0 25px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .top_navbar .hamburger{
        font-size: 25px;
        cursor: pointer;
        color: white;
    }

    .top_navbar .hamburger:hover{
        color: #007dc3;
    }

    /* Now I have determined what the sidebar will look like for a responsive device */
    .hover_collapse .sidebar{
        width: 80px;
    }

    .hover_collapse .sidebar ul li a .text{
        display: none;
    }

    .sidebar{
        position: fixed;
        top: 60px;
        left: 0;
        width: 300px;
        height: 100%;
        background: #163a5f;
    }

    .sidebar ul li a{
        display: block;
        padding: 16px 25px;
        color: #0e94d4;
        text-decoration: none;
        cursor: pointer;
    }

/* .sidebar ul li a .icon{
	font-size: 18px;
  color: white;
	vertical-align: middle;

} */

    .sidebar ul li a .text{
        color: #fff;
        margin-left: 25px;
    }

/* .sidebar ul li :hover{
	 background: #0e94d4;
	color: #fff; 
} */

    .click_collapse .sidebar{

    }
    
`

const SideBarContainer = styled.div`
    .top-navbar{
        z-index: 3;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 60px;
        background: #163a5f;
        box-shadow: 1px 0 2px rgba(0,0,0,0.125);
        display: flex;
        align-items: center;
        @media only screen and (min-width: 1100px) {
            display:none;
        }
    }

    .sidebar{
        z-index:2;
        position: fixed;
        left: 0;
        width: 20%;
        height: 100vh;
        background: #163a5f;
        @media only screen and (max-width: 1100px) {
            display:none;
            //margin-top:60px;
            width: 200px;
        }
    }

    .sidebar-logo{
        height: 3.5rem;
        @media only screen and (max-width: 1100px) {
            display:none;
        }
        @media only screen and (min-width: 1100px) {
            display:block;
        }
        cursor: pointer;
    }

    .sidebar-link{
        text-decoration: none;
        color: #ffffff;
        :hover{
            background: #0e94d4;
        }
        
        
    }
    @media only screen and (max-width: 1100px) {
        .db-link{
                margin-top:25px;
        }
    }
    

    .logout{
        position: absolute;
        //bottom: 60px;
        width: 100%;
    }

    a {
        display: block;
        padding: 10px;
    }
`
